/* productListPage > productListPage-searcArea */
.productListPage-searcArea {
  width: 100%;
}
.productListPage-searcArea .ec-searchnavRole__infos {
  width: 100%;
  height: 50px;
  padding: 0;
  margin: 0 0 40px;
  border: none;
}
.productListPage-searcArea .ec-searchnavRole__infos .ec-searchnavRole__counter {
  display: flex;
  align-items: center;
  width: calc(100% - 260px);
}
.productListPage-searcArea .ec-searchnavRole__infos .ec-searchnavRole__counter .productListPage-resultNote {
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 14px;
}
.productListPage-searcArea .ec-searchnavRole__infos .ec-searchnavRole__actions {
  width: 260px;
}
.productListPage-searcArea .ec-searchnavRole__infos .ec-searchnavRole__actions .ec-select {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
}
.productListPage-searcArea .ec-searchnavRole__infos .ec-searchnavRole__actions .ec-select .disp-number,
.productListPage-searcArea .ec-searchnavRole__infos .ec-searchnavRole__actions .ec-select .order-by {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  height: 50px;
  padding: 0 35px 0 20px;
  background: url(/html/template/default/assets/img/common/icon_select.svg) no-repeat right 15px center #fff;
  background-size: 10px auto;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  font-size: 13px;
  line-height: normal;
  cursor: pointer;
}
.productListPage-searcArea .ec-searchnavRole__infos .ec-searchnavRole__actions .ec-select .disp-number {
  width: 100px;
}
.productListPage-searcArea .ec-searchnavRole__infos .ec-searchnavRole__actions .ec-select .order-by {
  width: calc(100% - 110px);
}


/* productListPage > productListPage-mainArea */
.productListPage-mainArea {
  width: 100%;
  padding-bottom: 80px;
  position: relative;
}
.productListPage-mainArea-list {
  width: 100%;
  margin: 0%;
}
.productListPage-mainArea .productListPage-mainArea-list .productListPage-mainArea-list-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(25% - 50.25px);
  padding: 0;
  margin: 0 67px 60px 0;
}
.productListPage-mainArea .productListPage-mainArea-list .productListPage-mainArea-list-item:nth-child(4n) {
  margin-right: 0;
}
.productListPage-mainArea .ec-shelfGrid__item-image {
  height: auto;
  margin-bottom: 10px;
}
.productListPage-mainArea .productListPage-mainArea-productInfo {
  display: block;
  padding-bottom: 15px;
}
.productListPage-mainArea .productListPage-productInfo-text {
  font-size: 14px;
  line-height: 2;
}
.productListPage-mainArea-productForm .ec-select {
  margin-bottom: 7px;
}
.productListPage-mainArea-productForm .ec-numberInput {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.productListPage-mainArea-productForm .ec-numberInput input[type='number'] {
  width: 85px;
  height: 24px;
  padding: 3px 10px 0;
  margin: 0;
  border: 1px solid #707070;
  border-radius: 0;
  font-size: 14px;
}
.productListPage-mainArea-productForm .ec-numberInput .btn-reserved {
  display: inline-block;
  width: 50px;
  height: 24px;
  padding: 0;
  background-color: #707070;
  border: none;
  color: #fff;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  vertical-align: baseline;
}
.productListPage-mainArea-productForm .ec-numberInput .btn-reserved:hover {
  opacity: .8;
}
/* .productListPage-favArea */
.productListPage-favArea {
  width: 20px;
  &-inner {
    display: block;
    width: 100%;
    height: 100%;
    background: url(../icon/heart_red_line.svg) no-repeat center center;
    background-size: 100% auto;
    cursor: pointer;
    &:hover {
      opacity: .8;
    }
    &.is-active {
      background-image: url(../icon/heart_red.svg);
    }
  }
}
.productListPage-cartBtnArea {
  width: 280px;
  position: fixed;
  bottom: 80px;
  right: 30px;
}
.productListPage-cartBtnArea .ec-blockBtn--action,
.productListPage-cartBtnArea .ec-blockBtn--cancel {
  width: 100%;
  height: 60px;
  font-weight: 500;
  font-size: 16px;
}
.productListPage-cartBtnArea .ec-blockBtn--action:hover,
.productListPage-cartBtnArea .ec-blockBtn--cancel:hover {
  opacity: .8;
}
.productListPage-cartBtnArea .ec-blockBtn--action,
.productListPage-cartBtnArea .ec-blockBtn--action:hover {
  background-color: #BF0404;
  border-color: #BF0404;
}
.productListPage-cartBtnArea .ec-blockBtn--cancel,
.productListPage-cartBtnArea .ec-blockBtn--cancel:hover {
  background-color: #707070;
  border-color: #707070;
}
.productListPage-mainArea .ec-pagerRole .ec-pager {
  text-align: left;
}


/* productListPage-catArea */
.productListPage-catArea {
  width: 100%;
  margin-bottom: 20px;
}
.productListPage-catArea-title {
  display: block;
  width: 100%;
  padding: 10px 35px 10px 15px;
  background-color: #343434;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  position: relative;
  cursor: pointer;
}
.productListPage-catArea-title:hover {
  opacity: .8;
}
.productListPage-catArea-title::before,
.productListPage-catArea-title::after {
  content: '';
  display: block;
  width: 16px;
  height: 1px;
  background-color: #fff;
  position: absolute;
}
.productListPage-catArea-title::before {
  top: 18px;
  right: 10px;
}
.productListPage-catArea-title::after {
  transform: rotate(90deg);
  top: 18px;
  right: 10px;
  opacity: 1;
  transition: opacity .3s ease;
}
.productListPage-catArea-title.is-open::after {
  opacity: 0;
}
.productListPage-catArea-main {
  // display: none;
  width: 100%;
}
.productListPage-catArea-main-inner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 17px 0 20px;
}
.productListPage-catArea-main-img {
  display: block;
  width: calc(100% - 440px);
  padding: 0;
  margin: 0;
}
.productListPage-catArea-main-img img {
  display: block;
  width: 100%;
  height: auto;
}
.productListPage-catArea-main-body {
  width: 440px;
  padding: 15px 0 0 23px;
  font-size: 14px;
  line-height: 1.8;
}
.productListPage-catArea-main-body p {
  font-size: 14px;
  line-height: 1.8;
}
.productListPage-catArea-main-btn {
  width: 100%;
  padding: 13px 0 0;
  margin: 0;
}
