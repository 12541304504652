.orderhistoryPage .ec-mypageRole .ec-pageHeader h1 {
  margin-bottom: 38px;
}

/* orderhistoryPage-formArea */
.orderhistoryPage-formArea {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-bottom: 34px;
}
.orderhistoryPage-formArea-item {
  display: flex;
  align-items: center;
  width: auto;
  height: 50px;
}
.orderhistoryPage-formArea-term {
  padding: 0 8px 0 0;
  color: #000000;
  font-weight: normal;
  font-size: 14px;
}
.orderhistoryPage-formArea-main {
  display: flex;
  align-items: center;
  width: auto;
  height: 100%;
}
.orderhistoryPage-orderPeriod-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 100%;
  padding: 0 14px;
  background-color: #fff;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  color: #343434;
  font-weight: 500;
  font-size: 13px;
  line-height: 0;
}
.orderhistoryPage-formArea-main-dateInput {
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.orderhistoryPage-formArea-main-dateInput input[type="date"].orderhistoryPage-orderPeriod-input {
  width: 200px;
  padding: 0 10px;
  border: none;
  position: relative;
}
.orderhistoryPage-formArea-main-dateInput input[type="date"].orderhistoryPage-orderPeriod-input::-webkit-calendar-picker-indicator {
  width: 110px;
  height: 100%;
  color: transparent;
  cursor: pointer;
  background: transparent;
  position: absolute;
  top: 0;
  left: -24px;
}
.orderhistoryPage-orderPeriod .is-dash {
  padding: 0 5px;
  font-size: 14px;
}
.orderhistoryPage-orderUser,
.orderhistoryPage-orderState {
  margin-left: 18px;
}
.orderhistoryPage-orderUser-select,
.orderhistoryPage-orderState-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  width: 300px;
  height: 100%;
  padding: 0 30px 0 15px;
  background: url(/html/template/default/assets/img/common/icon_select.svg) no-repeat right 15px center #fff;
  background-size: 10px auto;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  color: #000000;
  font-size: 14px;
  line-height: normal;
  cursor: pointer;
}
.orderhistoryPage-orderState-select {
  width: 100px;
  font-size: 13px;
}
.orderhistoryPage-search {
  width: 180px;
}

/* orderhistoryPage-table */
.orderhistoryPage-tableArea {
  padding-right: 65px;
}
.orderhistoryPage-table.ec-historyRole {
  position: relative;
}
.orderhistoryPage-table.ec-cartRow li {
  display: flex;
  align-items: center;
  padding: 30px 8px;
}
.ec-cartRow .orderhistoryPage-table-re {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  width: 100px;
  height: 100%;
  padding: 0;
  border: none;
  position: absolute;
  top: 0;
  right: -100px;
}
.orderhistoryPage-table-reBtn {
  display: block;
  width: 100px;
  padding: 4px;
  background-color: #BF0404;
  border: none;
  color: #fff;
  border-radius: 20px;
  border: none;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: normal;
}
.orderhistoryPage-table-reBtn + .orderhistoryPage-table-reBtn {
  margin-top: 5px;
}
.orderhistoryPage-table-reBtn:hover {
  color: #fff;
  opacity: .8;
}
.ec-cartRow .orderhistoryPage-table-num,
.ec-cartRow .orderhistoryPage-table-state,
.ec-cartRow .orderhistoryPage-table-deliv {
  justify-content: center;
}
.orderhistoryPage-table-num {
  width: 100px;
}
.orderhistoryPage-table-num a {
  color: #343434;
  text-align: center;
}
.orderhistoryPage-table-date {
  width: 103px;
}
.orderhistoryPage-table-state {
  width: 63px;
}
.orderhistoryPage-table-user {
  width: 176px;
}
.orderhistoryPage-table-price {
  width: 102px;
}
.ec-cartRow .orderhistoryPage-table-price {
  text-align: right;
}
.orderhistoryPage-table-address {
  // width: 173px;
  width: 30%;
}
.orderhistoryPage-table-deliv {
  width: 104px;
}

/* orderhistoryPage-csvArea */
.orderhistoryPage-csvArea {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}
.orderhistoryPage-csvArea-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 60px;
  background-color: #F9F9F9;
  border: 1px solid #CCCCCC;
  border-radius: 30px;
  color: #343434;
  font-weight: 500;
  font-size: 14px;
  line-height: normal;
}
.orderhistoryPage-csvArea-btn:hover {
  opacity: .8;
}