.ec-progress .ec-progress__item:after {
  background-color: #CCCCCC;
}
.ec-progress .ec-progress__number {
  font-family: "游ゴシック Medium","Yu Gothic Medium","游ゴシック体",YuGothic,sans-serif;
  font-weight: 500;
  background-color: #707070;
}
.ec-progress .is-complete .ec-progress__number {
  background-color: #767DBB;font-family: 'Noto Sans JP', sans-serif;
}
.ec-progress .ec-progress__label {
  font-family: 'Noto Sans JP', sans-serif;
  color: #343434;
  font-weight: 500;
}
.ec-progress .is-complete .ec-progress__label {
  color: #767DBB;
}
.ec-cartRole .ec-cartRole__totalText {
  margin-bottom: 45px;
}
.ec-cartRole .ec-cartRole__totalText p {
  color: #343434;
  font-size: 16px;
}
.ec-cartRole .ec-cartRole__totalText p strong {
  color: #BF0404;
}
#form_cart {
  width: 100%;
  margin: 0 0 40px;
}
.ec-cartRole .ec-cartRole__cart {
  margin: 0;
}
.ec-cartTable {
  padding-right: 27px;
}
.productDetailPage-campaignArea-header li,
.ec-cartHeader .ec-cartHeader__label {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 52px;
  padding: 6px 0;
  background-color: #343434;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  overflow: visible;
}
.productDetailPage-campaignArea-header li.bg-yellow,
.ec-cartHeader .ec-cartHeader__label.bg-yellow {
  background-color: #F19800;
}
.ec-cartRow {
  position: relative;
}
.productDetailPage-campaignArea-item li,
.ec-cartHeader li,
.ec-cartRow li {
  border-left: 1px dotted #ccc;
}
.productDetailPage-campaignArea-detail li,
.ec-cartRow li {
  padding: 25px 8px;
  border-bottom: 1px dotted #ccc;
}
.productDetailPage-campaignArea-header li:first-child,
.ec-cartHeader li:first-child {
  border-left: none;
}
.productDetailPage-campaignArea-detail li:last-child,
.ec-cartRow li:last-child {
  border-right: 1px dotted #ccc;
}
.productDetailPage-campaignArea-item,
.cartPage-table {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
}
.cartPage-table .cartPage-table-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 100%;
  padding: 0;
  border: none;
  position: absolute;
  top: 0;
  right: -27px;
}
.cartPage-table .cartPage-table-delete .ec-icon {
  display: block;
  width: 21px;
  height: 21px;
  background: url(/html/template/default/assets/icon/cross-white.svg) no-repeat center center #CCCCCC;
  background-size: 11px auto;
  border-radius: 100%;
  text-indent: -1000px;
  overflow: hidden;
}
.cartPage-table .cartPage-table-no {
  width: 100px;
}
.cartPage-table .cartPage-table-mysku {
  width: 64px;
}
.cartPage-table.ec-cartRow .cartPage-table-mysku {
  text-align: center;
}
.cartPage-table .cartPage-table-name {
  width: calc(100% - 523px);
}
.cartPage-table.ec-cartRow .cartPage-table-name {
  padding: 25px 8px;
}
.cartPage-table.ec-cartRow .cartPage-table-name a {
  color: #343434;
}
.cartPage-table .cartPage-table-count {
  width: 97px;
}
.cartPage-table .cartPage-table-count .cartPage-table-count-inner {
  display: block;
  width: 100%;
  padding: 1px 6px;
  border: 1px solid #CCCCCC;
  text-align: right;
  font-size: 13px;
  line-height: normal;
}
.cartPage-table .cartPage-table-unit {
  width: 55px;
}
.cartPage-table.ec-cartRow .cartPage-table-unit {
  text-align: center;
}
.cartPage-table .cartPage-table-orderunit {
  width: 50px;
}
.cartPage-table.ec-cartRow .cartPage-table-orderunit {
  text-align: center;
}
.cartPage-table .cartPage-table-price {
  width: 103px;
}
.cartPage-table.ec-cartRow .cartPage-table-price {
  text-align: right;
}
.cartPage-table .cartPage-table-total {
  width: 114px;
}
.cartPage-table.ec-cartRow .cartPage-table-total {
  text-align: right;
  font-weight: 500;
  font-size: 16px;
}

/* cartPage-actionArea */
.ec-cartRole .cartPage-actionArea {
  width: 100%;
  padding: 0 42px 0 0;
  margin: 0;
}
.cartPage-actionArea-note {
  margin-bottom: 18px;
  color: #343434;
  text-align: right;
  font-weight: normal;
  font-size: 12px;
}
.ec-cartRole .cartPage-totalArea {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 30px 0 20px;
  font-weight: normal;
}
.cartPage-totalArea-reCalc {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  padding: 0;
  margin: 0 46px 0 0;
  background: #F9F9F9;
  border: 1px solid #CCCCCC;
  border-radius: 20px;
  color: #343434;
  font-weight: normal;
  font-size: 14px;
  line-height: 0;
  cursor: pointer;
}
.cartPage-totalArea-reCalc:hover {
  opacity: .8;
}
.cartPage-totalArea-main {
  width: auto;
  font-weight: 500;
  font-size: 14px;
}
.ec-cartRole .cartPage-totalArea-main .ec-cartRole__totalAmount {
  color: #BF0404;
  font-size: 24px;
}
.cartPage-actionArea-btnArea {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}
.cartPage-actionArea-btnArea .ec-blockBtn--action,
.cartPage-actionArea-btnArea .ec-blockBtn--cancel {
  width: 350px;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 14px;
}
