.newsPage-main {
  width: 100%;
  padding: 0 20px;
  margin-bottom: 40px;
}
.newsPage-main-item {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #CCCCCC;
  color: #343434;
  font-size: 14px;
}
.newsPage-main-date {
  width: 148px;
  padding: 18px 5px 18px 0;
  font-weight: normal;
  line-height: 1.8;
}
.newsPage-main-body {
  width: calc(100% - 148px);
  padding: 18px 0;
  line-height: 1.8;
}
.newsPage-pager .ec-pager {
  width: 100%;
  padding: 20px 0;
  text-align: left;
}