.topPage-recommendArea {
  width: 100%;
  &-inner {
    width: 100%;
    &.is-login {
      display: flex;
      flex-wrap: wrap;
    }
  }
  &-item {
    width: 100%;
    padding: 0;
    margin: 0 0 50px;
    .is-login & {
      display: flex;
      flex-wrap: wrap;
      width: calc(33.3% - 33.3px);
      margin: 0 50px 0 0;
      &:nth-child(3n) {
        margin-right: 0;
      }
    }
  }
  &-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 75px);
    padding: 0;
    margin: 0;
    &-title {
      display: block;
      padding: 8px 5px 9px;
      margin: 0;
      background-color: #767DBB;
      color: #fff;
      text-align: center;
      .is-purchase & {
        background-color: #F19800;
      }
      .is-favorite & {
        background-color: #95C228;
      }
      .is-ja,
      .is-en {
        display: block;
        width: 100%;
        text-align: center;
        font-weight: normal;
      }
      .is-ja {
        font-size: 20px;
      }
      .is-en {
        font-size: 12px;
        line-height: 1;
      }
    }
    &-list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
      padding: 30px;
      margin: 0;
      border: 1px solid #CCCCCC;
      border-top: none;
      .is-logout & {
        padding: 26px;
      }
      &-item {
        width: 100%;
        padding: 0;
        margin: 0;
        .is-login .is-recommend & {
          display: none;
          &:first-child,
          &:nth-child(2) {
            display: block;
          }
        }
        & + .topPage-recommendArea-main-list-item {
          margin-top: 50px;
        }
        .is-logout & {
          width: calc(25% - 45px);
          margin: 0 60px 0 0;
          &:nth-child(4n) {
            margin-right: 0;
          }
          & + .topPage-recommendArea-main-list-item {
            margin-top: 30px;
          }
        }
        .is-inner {
          display: block;
          width: 78.6%;
          margin: 0 auto;
        }
        &-img {
          display: block;
          width: 100%;
          height: 0;
          padding-top: 100%;
          margin-bottom: 10px;
          position: relative;
          &-inner {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
          }
          img {
            display: block;
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: 100%;
            padding: 0;
            object-fit: contain;
          }
        }
        .is-name {
          display: block;
          width: 100%;
          color: #343434;
          text-align: center;
          font-size: 14px;
          line-height: 1.6;
        }
      }
    }
  }
  &-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 73px;
  }
}