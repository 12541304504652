/* 共通 */
ul,li {
  list-style: none;
  margin: 0;
  padding: 0;
}
a {
  display: block;
  width: 100%;
  text-decoration: none;
  &:hover {
    opacity: 0.6;
    text-decoration: none;
  }
}
body {
  font-family: 'Noto Sans JP', sans-serif;
}

/* common */
@import "kuretake/common";


/* header */
.ec-layoutRole__header {
  width: 100%;
}
.ec-headerSearch .ec-headerSearch__keyword input[type=search] {
  @media only screen and (min-width: 768px) {
    font-size: 18px;
  }
}
/* is-hNavR__left タイトルロゴ */
.is-hNavR__left {
  img {
    width: 182px;
    height: auto;
    display: block;
  }
}
/* is-header-block */
.is-header-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0;
  max-width: 1110px;
  width: 100%;
  margin: 0 auto;
}
/* is-hNavR__right */
.ec-headerNaviRole .ec-headerNaviRole__right {
  width: 495px;
  padding-top: 12px;
}
.is-hNavR__right {
  &._guide {
    min-width: 84px;
    padding-right: 39px;
    a {
      display: block;
      width: 100%;
    }
    & .is-icon {
      display: block;
      height: auto;
      margin: 0 auto 15px;
      img {
        width: 15px;
        display: block;
        height: auto;
        margin: 0 auto;
      }
    }
  }
  .ec-headerNav__item {
    padding: 0;
  }
  .ec-headerNav__item a,
  .is-cartNavi a {
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 52px;
    padding: 0 15px;
    margin: 0;
    background: url(../img/common/icon_cart.png) no-repeat top center transparent;
    background-size: 30px auto;
    text-align: center;
    text-decoration: none;
    color: #707070;
  }
  .ec-headerNav__item.is-guide a {
    background-image: url(../img/common/icon_guide.png);
  }
  .ec-headerNav__item.is_logout a {
    background-image: url(../img/common/icon_logout.png);
  }
  .ec-headerNav__item.is_login a {
    background-image: url(../img/common/icon_login.png);
  }
  .ec-headerNav__item.is-heartIcon a {
    background-image: url(../img/common/icon_favo.png);
  }
  .ec-headerNav__item.is-userIcon a {
    background-image: url(../img/common/icon_mypage.png);
  }
  &._cart {
    & .ec-cartNaviWrap {
      & .is-cartNavi {
        a {
          padding-right: 5px;
          position: relative;
          background-position: top right 12px;
          .ec-cartNavi__badge {
            margin: 0;
            position: absolute;
            top: -10px;
            right: 0;
            left: auto;
          }
        }
      }
    }
    &._link {
      font-size: 14px;
    }
  }
}


/* 神社仏閣 */
.is-block__headerShrine {
  display: flex;
  align-items: center;
  width: calc(100% - 677px);//182px + 495px
  height: 65px;
  padding: 0 0 0 17px;
  margin: 0 auto;
  &_Link {
    width: 140px;
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 30px;
      padding: 0;
      margin: 0;
      background-color: #F9F9F9;
      border-radius: 20px;
      border: solid 1px #ccc;
      color: #343434;
      text-align: center;
      font-size: 12px;
      text-decoration: none;
    }
  }
  &_userName {
    width: calc(100% - 140px);
    height: auto;
    padding: 0 20px 0 0;
    margin: 0;
    color: #707070;
    text-align: right;
    font-size: 14px;
    & .companyName {
      padding: 0 8px 0 0;
    }
  }
}
/* gNav */
.is-header-gNav {
  background: #343434;
  margin-bottom: 36px;
  &-list {
    display: flex;
    max-width: 1110px;
    margin: 0 auto;
    width: 100%;
    &::after {
      content: '';
      border-left: solid 1px #fff;
      display: inline-block;
      height: 22px;
      padding: 0;
      margin: auto;
    }
  }
  &-item {
    background: #343434;
    padding: 9px 0;
    margin: 0;
    text-align: center;
    width: calc(100% / 8);
    height: 40px;
    .is-title {
      color: #fff;
      display: inline-block;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 14px;
      border-left: solid 1px #fff;
      text-decoration: none;
      padding: 0;
      margin: 0;
    }
  }
}
/* footer */
.is-footerRole {
  & .is-footer__bg {
    background: #eee;
    display: flex;
  }
}
/* footer copyright */
.is-footerRole__copyright {
  font-size: 12px;
  color: #343434;
  text-align: center;
  width: 313px;
  margin: 0 auto;
  padding: 5px 0 3px;
  line-height: 1.7;
}
.is-footerRole {
  border: none;
}
.is-footerRole__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 664px;
  width: calc(100% - 493px);
  padding: 26px 0 21px;
  & .is-footerTitle {
    &__logo {
      max-width: 151px;
      width: 100%;
      margin: 0 auto;
    }
  }
  & .is-footerNavBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 493px;
    &__right {
      padding-left: 30px;
    }
  }
}

/*   search_product.twig
------------------------ */
.is-headerSearch {
  & .is-hs__key {
    background-color: transparent;
    & .ec-icon {
      height: auto;
    }
  }
}
.is-headerSearch {
  float: none;
  width: 100%;
  background: #f9f9f9;
  height: 150px;
  padding: 0 10px;
  margin-bottom: 20px;
  & .is-hs__key {
    width: 100%;
    float: none;
    border: none;
    max-width: 325px;
    margin: 0 auto;
    &:hover {
      opacity: .8;
    }
  }
  & .is-hs__keybtn {
    display: block;
    position: relative;
    right: unset;
    transform: none;
    background: #f19800;
    border-radius: 30px;
    color: #fff;
    text-align: center;
    height: auto;
    padding: 6px 0 5px;
    top: unset;
    width: 136px;
    margin: 0 auto;
  }
  & .is-hs__key {
    input {
      &.search-name {
        color: #525263;
        display: block;
        background-color: #fff;
        border: solid 2px #cccccc;
        border-radius: 0;
        margin: 0 auto 10px;
        width: 100%;
        height: 36px;
        padding: 10px 0 10px 13px;
        &::placeholder {
          color: #cfcfcf;
        }
      }
    }
  }
}
.is-headerSearch {
  &__category {
    img {
      width: 20px;
    }
    & .is-select_Search {
      background: none;
      border-radius: 0;
      border: 0;
      color: transparent;
      padding: 25px 0 12px;
    }
  }
}

.is-select_Search {
  &::before {
    display: none !important;
  }
}

.is-hs__key {
  width: 100%;
  .is-input {
    width: 100%;
  }
}

/*   category_nav_pc.twig
------------------------ */
.is-asidNav__title {
  background: #707070;
  color: #fff;
  font-size: 14px;
  opacity: .9;
  padding: 3.5px 0;
  text-align: center;
  position: relative;
}
.is-cateNav {
  display: block !important;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  .is-asidNav__title {
    cursor: pointer;
    &::after {
      content: '';
      display: block;
      width: 30px;
      height: 100%;
      background: url(../img/common/icon_open_wh.svg) no-repeat center center;
      background-size: 15px auto;
      position: absolute;
      top: 0;
      right: 0;
    }
    &.is-active::after {
      background-image: url(../img/common/icon_close_wh.svg);
    }
  }
}
.categoryNav-inner {
  .categoryNav-list {
    display: block;
    width: 100%;
    li {
      display: block;
    }
  }
}
.ec-itemNav__nav li a,
.ec-itemNav__nav li ul li a {
  font-weight: bold;
}
.categoryNav-inner {
  display: none;
  &.first-open {
    display: block;
  }
  .categoryNav-list{
    .categoryNav-list-item {
      &:hover {
        opacity: 0.6;
      }
      span {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        min-height: 60px;
        text-align: left;
        font-size: 16px;
        line-height: 16px;
        color: #585858;
        padding: 20px 50px 23px 12px;
        background-color: transparent;
        background-image: no-repeat left center;
        background-size: 40px auto;
        border-bottom: 1px solid #eee;
        cursor: pointer;
        position: relative;
        &::after {
          content: '';
          display: block;
          width: 30px;
          height: 100%;
          background: url(../img/common/icon_open.svg) no-repeat center center;
          background-size: 15px auto;
          position: absolute;
          top: 0;
          right: 0;
        }
        &.is-active::after {
          background-image: url(../img/common/icon_close.svg);
        }
        a {
          display: block;
          border: none;
          background: transparent;
          text-align: left;
          font-size: 14px;
          font-weight: bold;
          color: #333333;
          position: relative;
          cursor: pointer;
        }
        &:hover {
          opacity: 0.6;
        }
      }
      ul {
        min-width: auto;
        padding: 0 0 4px 26px;
        position: relative;
        left: 0;
        li {
          overflow: visible;
          height: auto;
          span {
              display: block;
              border: none;
              background: transparent;
              text-align: left;
              font-size: 14px;
              font-weight: 300;
              color: #333333;
              position: relative;
              cursor: pointer;
            a &:hover {
              opacity: 0.6;
            }
          }
        }
      }
    }
  }
}
.categoryNav-list-item {
  a {
    padding: 20px 0 23px 12px;
  }
}

/* asidNav-sceneArea */
.asidNav-sceneArea {
  width: 100%;
  &-list {
    width: 100%;
    border: 1px solid #CCCCCC;
    border-bottom: none;
    &-item {
      width: 100%;
      border-bottom: 1px solid #CCCCCC;
      .is-inner {
        display: block;
        width: 100%;
        padding: 12px 5px;
        text-align: center;
        color: #343434;
        font-size: 14px;
        line-height: 1.5;
      }
    }
  }
}

/*   check_product.twig
------------------------ */
.is-checProductRole {
  margin-bottom: 23px;
}
.ec-checkProductRole__listItem {
  padding: 25px 12px 15px!important;
  & .item_photo {
    width: 100%;
    display: inline-flex;
    img {
      max-width: 70px;
      height: 69px;
    }
  }
  &.is-checkProductRole__inner {
    display: block;
    width: 100%;
  }
  .ec-checkProductRole__listItemTitle {
    color: #343434;
    line-height: 1.5;
  }
  .is-checkProductRole__listItemTitleTop {
    margin-bottom: 20px;
  }
}

/*   apg_sc_two_month_calendar.twig
-------------------------------------- */
.is-topCalendar {
  border: solid 1px #eee;
  table.apg_sc_table {
    width: 100%;
    height: auto;
    display: inline-table;
    text-align: center;
    & .apg_sc_header {
      text-align: center;
      padding: 10px 0;
      background: #F9F9F9;
    }
  }
}
.is-apg_sc_weekList {
  th {
    border-top: 1px solid #eee;
    text-align: center;
    background: #F9F9F9;
    padding: 5px 0px;
    & + th {
      border-left: 1px solid #eee;
    }
  }
}
.is-apg_sc_daysList {
  td {
    color: #707070;
    border-top: 1px solid #eee;
    padding: 5px;
    & + td {
      border-left: 1px solid #eee;
    }
    &.apg_sc_sunday,
    &.apg_sc_holiday,
    &.apg_sc_saturday {
      background: rgba(191,4,4,0.3);
    }
    &.apg_sc_non_day {
      background: #fff;
    }
  }
}

/*   index.twig
------------------------ */
/*  メインビュー スライダー */
.slick-slide {
  img {
    padding: 0 0 0 25px;
  }
}

/*   top_index.twig
------------------------ */
.is-indexRole {
  background: none;
}
.is-index__list {
  border: none;
  padding: 0;
  margin: 0 12.5px !important;
  display: block;
  text-align: center;
  &:first-child {
    margin-left: 0 !important;
  }
  &:last-child {
    margin-right: 0 !important;
  }
  .is-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 84px;
    padding: 0 10px;
    border: solid 1px #ccc;
    color: #343434;
  }
}
/*   news.twig
------------------------ */
.ec-newsRole {
  padding-top: 10px;
  margin-bottom: 70px;
  .ec-newsRole__newsItem {
    width: 100%;
    padding: 0;
    margin: 0;
    border-bottom: solid 1px #B5B5B5;
    &:hover {
      opacity: .8;
    }
  }
  .ec-newsRole__newsColumn {
    display: flex;
    width: calc(100% - 145px);
    min-width: auto;
  }
  .ec-newsRole__newsClose {
    width: 30px !important;
    .ec-newsRole__newsCloseBtn {
      display: block;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      background: transparent;
      border-radius: 0;
      position: relative;
      right: auto;
      &::before,
      &::after {
        content: '';
        display: block;
        width: 15px;
        height: 1px;
        background: #707070;
        position: absolute;
        top: calc(50% - 1px);
        left: 0;
      }
      &::after {
        transform: rotate(-90deg);
      }
      i {
        display: none!important;
      }
    }
  }
  .ec-newsRole__newsDate,
  .ec-newsRole__newsTitle {
    color: #333;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.6;
    padding: 18px 0;
    margin: 0;
  }
  .ec-newsRole__newsDate {
    display: block;
    width: 145px;
  }
  .ec-newsRole__newsTitle {
    width: calc(100% - 30px);
    padding-right: 10px;
  }
  .ec-newsRole__newsDescription {
    width: 100%;
    padding: 0 0 20px;
    margin: 0!important;
    line-height: 1.6;
  }
  .ec-newsRole-bottom {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 40px;
    margin: 0;
  }
}



/* topPage */
@import "kuretake/recommendArea";
@import "kuretake/industryPage";

/* product page */
@import "kuretake/productDetailPage";
@import "kuretake/productListPage";

/* news page */
@import "kuretake/newsPage";

/* cart page */
@import "kuretake/cartPage";

/* shopping page */
@import "kuretake/shoppingPage";

/* entry page */
@import "kuretake/entryPage";

/* mypage */
.ec-mypageRole {
  padding: 0;
}
.ec-login {
  background: transparent;
}
@import "kuretake/bulkorderPage";
@import "kuretake/skuPage";
@import "kuretake/orderhistoryPage";
@import "kuretake/orderhistoryDetailPage";
@import "kuretake/underPage";


