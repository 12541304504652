.ec-pageHeader h1 {
  padding: 0 0 10px;
  margin: 0 0 40px;
  border-bottom: 2px solid #EEEEEE;
  color: #525263;
  font-weight: normal;
  font-size: 32px;
}
.ec-rectHeading h2 {
  padding: 9px 20px;
  margin: 0 0 20px;
  background-color: #F9F9F9;
  color: #343434;
  font-weight: normal;
  font-size: 20px;
}
.commonText {
  margin-bottom: 10px;
}


/* breadcrumb */
.commonBreadcrumb {
  width: 100%;
  padding: 0;
  margin: 0 0 20px;
  color: #343434;
}
.commonBreadcrumb .commonBreadcrumb-item {
  display: inline;
  min-width: auto;
  color: #343434;
  font-weight: normal;
  font-size: 12px;
}
.commonBreadcrumb .commonBreadcrumb-item a {
  display: inline;
  color: #3C82F0;
  font-weight: normal;
  font-size: 12px;
}
.commonBreadcrumb .commonBreadcrumb-split {
  display: inline;
  font-weight: normal;
  font-size: 12px;
  margin: 0 3px;
}

/* common > btn */
.ec-blockBtn--action,
.ec-blockBtn--cancel {
  font-family: "游ゴシック Medium","Yu Gothic Medium","游ゴシック体",YuGothic,sans-serif;
  font-weight: 500;
  font-size: 16px;
}
.ec-blockBtn--action:hover,
.ec-blockBtn--cancel:hover {
  opacity: .8;
}
.ec-blockBtn--action,
.ec-blockBtn--action:hover {
  background-color: #BF0404;
  border-color: #BF0404;
}
.ec-blockBtn--cancel,
.ec-blockBtn--cancel:hover {
  background-color: #707070;
  border-color: #707070;
}

/* modal */
.ec-modal-box .ec-inlineBtn--cancel,
.ec-modal-box .ec-inlineBtn--action {
  width: 180px;
  font-family: "游ゴシック Medium","Yu Gothic Medium","游ゴシック体",YuGothic,sans-serif;
  font-weight: 500;
}
.ec-modal-box .ec-inlineBtn--cancel:hover,
.ec-modal-box .ec-inlineBtn--action:hover {
  opacity: .8;
}
.ec-modal-box .ec-inlineBtn--cancel,
.ec-modal-box .ec-inlineBtn--cancel:hover {
  background-color: #707070;
  border-color: #707070;
}
.ec-modal-box .ec-inlineBtn--action,
.ec-modal-box .ec-inlineBtn--action:hover {
  background-color: #BF0404;
  border-color: #BF0404;
}

/* commonBtn */
.commonBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 160px;
  height: 45px;
  padding: 0 48px 0 0;
  margin: 0 auto;
  background: url(../img/common/right_arrow.svg) no-repeat right 22px center #fff;
  background-size: 20px auto;
  border: 1px solid #707070;
  color: #707070;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.commonBtn:hover,
.commonBtn:focus {
  color: #707070;
  opacity: .8;
}

.commonDotsList {
  list-style-type: none;
  li {
    box-sizing: border-box;
    width: 100%;
    padding-left: 20px;
    position: relative;
    & + li {
      margin-top: 5px;
    }
    &::before {
      content: '';
      display: block;
      width: 3px;
      height: 3px;
      background-color: #525263;
      border-radius: 100%;
      position: absolute;
      top: 11px;
      left: 5px;
    }
  }
}
.commonNumList {
  list-style-type: none;
  counter-reset: commonNum;
  padding: 0;
  li {
    box-sizing: border-box;
    width: 100%;
    padding-left: 20px;
    position: relative;
    & + li {
      margin-top: 5px;
    }
    &::before {
      counter-increment: commonNum;
      content: counter(commonNum)". ";
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}