.shoppingPage-progress {
  margin-bottom: 50px;
}

/* shoppingPage-orderArea */
.shoppingPage-orderArea {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  margin: 0;
}

/* ec-orderRole__detail */
.shoppingPage-orderArea .ec-orderRole__detail {
  width: calc(100% - 331px);
  padding: 0 47px 0 0;
  margin: 0;
}
.shoppingPage-orderNumber {
  display: flex;
  align-items: center;
  width: 100%;
  height: 33px;
  padding: 0;
  margin: 0 0 40px;
}
.shoppingPage-orderNumber-term {
  width: 114px;
  padding: 0 0 0 8px;
  font-weight: normal;
  font-size: 16px;
}
.shoppingPage-orderNumber-num {
  display: flex;
  align-items: center;
  width: 200px;
  height: 100%;
  padding: 0 5px;
  border: 1px solid #707070;
}
#page_shopping_confirm .shoppingPage-orderNumber-num {
  border: none;
}
.ec-orderRole__detail .ec-orderDelivery {
  margin-bottom: 50px;
}
.shoppingPage-addressBtnArea {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0 0 25px;
}
.shoppingPage-addressBtnArea-item {
  height: 40px;
  padding: 0;
  margin: 0 10px 0 0;
  line-height: normal;
}
.ec-orderDelivery .shoppingPage-addressBtnArea-item.ec-orderDelivery__change {
  display: block;
  position: static;
  top: unset;
  right: unset;
}
.shoppingPage-addressBtnArea-item button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100%;
  padding: 0 30px;
  margin: 0;
  background-color: #F9F9F9;
  border: 1px solid #CCCCCC;
  border-radius: 20px;
  line-height: 0;
}
.shoppingPage-addressBtnArea-item button:hover {
  background-color: #F9F9F9;
  opacity: .8;
}
.shoppingPage-orderArea .ec-orderDelivery__address {
  width: 100%;
  margin: 0 0 26px;
}
.shoppingPage-orderArea .ec-orderDelivery__address p {
  font-size: 16px;
  line-height: 1.8;
}
.shoppingPage-orderArea .ec-orderDelivery__address .is-bold {
  font-weight: 500;
}
.shoppingPage-orderArea .shoppingPage-delivSelectArea {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  margin: 0;
}
.shoppingPage-orderArea .shoppingPage-delivSelectArea-term {
  display: flex;
  align-items: center;
  width: 120px;
  height: 50px;
  padding: 0 10px 0 0;
  margin: 0;
  color: #525263;
  font-weight: 700;
  font-size: 16px;
}
.shoppingPage-orderArea .shoppingPage-delivSelectArea-main {
  min-width: calc(100% - 120px);
  padding: 0;
  margin: 0;
  border: none;
}
#page_shopping_confirm .shoppingPage-orderArea .shoppingPage-delivSelectArea-main {
  display: flex;
  align-items: center;
  height: 50px;
}
.shoppingPage-orderArea .shoppingPage-delivSelectArea-main .ec-select {
  margin: 0 10px 0 0;
}
.shoppingPage-orderArea .shoppingPage-delivSelectArea-main .ec-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  width: auto;
  height: 50px;
  padding: 0 35px 0 20px;
  background: url(/html/template/default/assets/img/common/icon_select.svg) no-repeat right 15px center #fff;
  background-size: 10px auto;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  color: #343434;
  font-size: 13px;
  line-height: normal;
  cursor: pointer;
}
.shoppingPage-orderArea .shoppingPage-delivSelectArea-main .ec-select__delivery select {
  width: 150px;
}
.shoppingPage-orderArea .shoppingPage-delivSelectArea-main .ec-select__time select {
  width: 110px;
}
.shoppingPage-orderArea .ec-orderPayment {
  width: 100%;
  margin-bottom: 50px;
}
.shoppingPage-orderArea .ec-orderPayment .shoppingPage-radioArea {
  width: 100%;
}
.shoppingPage-orderArea .ec-orderPayment .shoppingPage-radioArea input[type=radio] {
  display: none;
}
.shoppingPage-orderArea .ec-orderPayment .shoppingPage-radioArea label {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.shoppingPage-orderArea .ec-orderPayment .shoppingPage-radioArea label span {
  display: block;
  width: 100%;
  padding: 0 0 0 34px;
  font-size: 16px;
  line-height: 1.8;
  position: relative;
}
.shoppingPage-orderArea .ec-orderPayment .shoppingPage-radioArea label span::before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background-color: #F9F9F9;
  border: 1px solid #CCCCCC;
  border-radius: 100%;
  position: absolute;
  top: 5px;
  left: 0;
}
.shoppingPage-orderArea .ec-orderPayment .shoppingPage-radioArea input[type=radio]:checked + label span::before {
  border-color: #767DBB;
}
.shoppingPage-orderArea .ec-orderPayment .shoppingPage-radioArea label span::after {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  background-color: #767DBB;
  border-radius: 100%;
  position: absolute;
  top: 9px;
  left: 4px;
  opacity: 0;
}
.shoppingPage-orderArea .ec-orderPayment .shoppingPage-radioArea input[type=radio]:checked + label span::after {
  opacity: 1;
}
.shoppingPage-orderArea .ec-orderConfirm .ec-input textarea {
  height: 160px;
  border-radius: 0;
}


/* ec-orderRole__summary */
.shoppingPage-orderArea .ec-orderRole__summary {
  width: 331px;
  padding: 0;
  margin: 0;
}
.shoppingPage-orderArea .ec-orderRole__summary .ec-totalBox {
  background-color: #F9F9F9;
}
.shoppingPage-orderArea .ec-orderRole__summary .ec-totalBox .ec-totalBox__spec {
  font-size: 16px;
}
.shoppingPage-orderArea .ec-orderRole__summary .ec-totalBox .ec-totalBox__total {
  padding-bottom: 40px;
  font-weight: 500;
  font-size: 14px;
}
.shoppingPage-orderArea .ec-orderRole__summary .ec-totalBox .ec-totalBox__total .ec-totalBox__price {
  color: #BF0404;
  font-weight: 500;
  font-size: 24px;
}
.shoppingPage-orderArea .ec-orderRole__summary .ec-totalBox .ec-totalBox__total .ec-totalBox__taxLabel {
  color: #BF0404;
  font-size: 14px;
}


/* shoppingPage-productList */
.shoppingPage-productList {
  width: 100%;
  margin-bottom: 40px;
  .ec-orderDelivery__item {
    max-height: 400px;
    overflow-y: scroll;
  }
}
.cart_page {
  .ec-cartRole__cart {
    max-height: 400px;
    overflow-y: scroll;
  }
}

/* shoppingPage-completeArea */
.shoppingPage-completeArea-progress {
  margin-bottom: 10px;
}
.shoppingPage-completeArea {
  width: 100%;
}
.shoppingPage-completeArea .shoppingPage-completeArea-title {
  width: 100%;
  padding: 0;
  margin: 0 0 33px;
  color: #343434;
  font-weight: bold;
  text-align: center;
  font-size: 32px;
}
.shoppingPage-completeArea .ec-reportDescription {
  width: 100%;
  max-width: 570px;
  padding: 0;
  margin: 0 auto 30px;
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
}
.shoppingPage-completeArea-numArea {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 280px;
  margin: 0 auto 30px
}
.shoppingPage-completeArea-numArea-term,
.shoppingPage-completeArea-numArea-num {
  width: 52%;
  padding: 0;
  margin: 0;
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.6;
}
.shoppingPage-completeArea-numArea-term {
  width: 48%;
  padding-right: 5px;
}
.shoppingPage-completeArea-numArea-num .is-pre {
  padding: 0 26px 0 0;
  margin: 0;
}
.shoppingPage-completeArea-text {
  width: 100%;
  margin-bottom: 54px;
  text-align: center;
}
.shoppingPage-completeArea-text a {
  display: inline;
  text-decoration: underline;
}
.shoppingPage-completeArea-btnArea .ec-blockBtn--cancel
.shoppingPage-completeArea-btnArea .ec-blockBtn--cancel:hover {
  background-color: #767DBB;
  border-color: #767DBB;
}