.ec-registerRole .ec-off1Grid .ec-off1Grid__cell {
  width: 100%;
  margin: 0;
}
.ec-registerRole .ec-registerRole__actions .ec-checkbox input {
  display: inline-block;
}
.ec-registerRole .ec-registerRole__actions label a {
  display: inline;
  color: #3C82F0;
  text-decoration: underline;
}