.skuPage .bulkorderPage-errorArea-tableArea .cartPage-table .cartPage-table-name {
  width: 320px;
}
.skuPage .bulkorderPage-errorArea-tableArea .cartPage-table .cartPage-table-type {
  width: 162px;
}
.bulkorderPage-errorArea-tableArea .cartPage-table .cartPage-table-mysku {
  width: 272px;
}
.bulkorderPage-errorArea-tableArea .cartPage-table.ec-cartRow .cartPage-table-mysku {
  padding: 25px 26px;
}
.bulkorderPage-errorArea-tableArea .cartPage-table .cartPage-table-mysku .cartPage-table-count-inner {
  display: block;
  width: 100%;
  padding: 4px 12px;
  border: 1px solid #CCCCCC;
  text-align: left;
  font-size: 14px;
  line-height: normal;
}
.skuPage-errorArea-pagerArea {
  width: 100%;
  margin-bottom: 10px;
}

/* skuPage-searchArea */
.skuPage-searchArea {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
}
.skuPage-searchArea .bulkorderPage-select {
  margin-right: 20px;
}
.skuPage-search-input {
  display: flex;
  align-items: center;
  width: 250px;
  height: 36px;
  padding: 0 20px;
  margin: 0 10px 0 0;
  background-color: #fff;
  border: 2px solid #CCCCCC;
  color: #525263;
  font-size: 14px;
}
.skuPage-search-input::placeholder {
  color: #A0A0A0;
}
.skuPage-search-btn {
  outline: none;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 136px;
  height: 30px;
  padding: 0;
  margin: 0;
  background-color: #F9F9F9;
  border: 1px solid #CCCCCC;
  border-radius: 20px;
  color: #343434;
  font-weight: 500;
  font-size: 14px;
}
.skuPage-search-btn:hover {
  opacity: .8;
}
