.industryPage {
  &-bnrArea {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    &-item {
      width: calc(33.3% - 24px);
      margin: 0 36px 40px 0;
      &:first-child {
        width: 100%;
        margin-right: 0;
      }
      &:nth-child(4) {
        margin-right: 0;
      }
      &:nth-child(3n + 4) {
        margin-right: 0;
      }
      .is-inner {
        display: block;
        width: 100%;
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}