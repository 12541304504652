.productDetailPage .productDetailPage-leftBlock {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .productDetailPage .productDetailPage-leftBlock {
    width: 340px;
  }
}
.productDetailPage .productDetailPage-rightBlock {
  width: 100%;
}
@media only screen and (min-width: 768px) {
  .productDetailPage .productDetailPage-rightBlock {
    width: calc(100% - 340px);
    padding-left: 34px;
  }
}
.productDetailPage .ec-productRole__profile {
  margin: 0;
}
.productDetailPage .ec-productRole__title .ec-headingTitle {
  margin-bottom: 10px;
  color: #343434;
  font-size: 28px;
  line-height: 1.5;
}
.productDetailPage .ec-productRole__description {
  margin-bottom: 24px;
  color: #555555;
  font-size: 14px;
  line-height: 1.5;
}

/* productDetailPage-leftBlock */
.productDetailPage-leftBlock .item_visual {
  margin-bottom: 34px;
}
.productListPage-mainArea .productDetailPage-mainImg,
.productDetailPage-leftBlock .productDetailPage-mainImg {
  display: block;
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative;
}
.productListPage-mainArea .productDetailPage-mainImg-inner,
.productDetailPage-leftBlock .productDetailPage-mainImg-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.productListPage-mainArea .productDetailPage-mainImg img,
.productDetailPage-leftBlock .productDetailPage-mainImg img {
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  padding: 0;
  -o-object-fit: contain;
    object-fit: contain;
}
.productDetailPage-leftBlock .ec-sliderItemRole .slideThumb {
  width: calc(33.3% - 13.3px);
  margin: 0 20px 20px 0;
  border: 1px solid #CCCCCC;
}
.productDetailPage-leftBlock .ec-sliderItemRole .slideThumb:nth-child(3n) {
  margin-right: 0;
}


/* productDetailPage > productDetailPage-priceArea */
.productDetailPage-priceArea {
  width: 100%;
  padding: 19px 0 17px;
  margin-bottom: 25px;
  border-top: 1px solid #C9C9C9;
  border-bottom: 1px solid #C9C9C9;
  font-size: 16px;
}

/* productDetailPage > productDetailPage-cartArea */
.productDetailPage-cartArea {
  width: 100%;
  margin-bottom: 40px;
}
.productDetailPage-cartArea-unit {
  margin-bottom: 25px;
  color: #525263;
  font-size: 14px;
}
.productDetailPage .ec-productRole__price {
  padding: 0;
  border: none;
  color: #525263;
  font-size: 16px;
}
.productDetailPage .ec-productRole__priceRegularPrice {
  font-family: 'Noto Serif JP', serif;
  color: #525263;
  font-weight: normal;
  font-size: 16px;
  line-height: normal;
}
.productDetailPage .ec-productRole__priceRegularTax {
  margin: 0;
  font-size: 12px;
} 
.productDetailPage .ec-productRole__price .ec-price__price {
  padding: 0;
  font-family: 'Noto Serif JP', serif;
  color: #BF0404;
  font-size: 29px;
}
.productDetailPage .ec-productRole__price .ec-price__tax {
  color: #BF0404;
  font-size: 16px;
}
.productDetailPage .ec-productRole__actions {
  padding: 10px 0;
}
.productDetailPage .ec-productRole__actions .ec-numberInput .is-pre {
  margin-right: 4px;
}
.productDetailPage .ec-productRole__actions .ec-numberInput .is-unit {
  margin-left: 5px;
}
.productDetailPage .ec-productRole__actions .ec-numberInput input[type='number'] {
  margin: 0;
}
.productDetailPage .ec-productRole__btn {
  margin: 20px 0 0;
}


/* productDetailPage > productDetailPage-infoArea */
.productDetailPage-infoArea {
  width: 100%;
  margin-bottom: 22px;
  border-bottom: 1px dotted #C9C9C9;
}
.productDetailPage-infoArea + .productDetailPage-infoArea {
  margin-top: 32px;
}
.productDetailPage-infoArea-item {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  margin: 0;
  border-top: 1px dotted #C9C9C9;
}
.productDetailPage-infoArea-term {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  background-color: #F9F9F9;
  color: #555555;
  text-align: center;
  font-weight: normal;
  font-size: 16px;
}
.productDetailPage-infoArea-description {
  width: calc(100% - 160px);
  padding: 14px 0 10px 18px;
  margin: 0;
  color: #555555;
  font-size: 14px;
  line-height: 1.6;
}

/* productDetailPage > productDetailPage-btnArea */
.productDetailPage-btnArea {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 10px);
}
.productDetailPage-btnArea-item {
  width: auto;
  max-width: calc(50% - 10px);
  min-width: 160px;
  margin: 0 10px 10px 0;
}
.productDetailPage-btnArea-item .productDetailPage-btnArea-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 35px;
  padding: 5px 30px 5px 50px;
  background: url(/html/template/default/assets/img/common/icon_pdf.png) no-repeat left 18px center #F9F9F9;
  background-size: 18px auto;
  border: 1px solid #CCCCCC;
  border-radius: 36px;
  overflow: hidden;
  color: #343434;
  font-size: 13px;
  line-height: 1.3;
}
.productDetailPage-btnArea-item .productDetailPage-btnArea-btn.icon-xls {
  background: url(/html/template/default/assets/img/common/icon_xls.svg) no-repeat left 17px center #F9F9F9;
  background-size: 18px auto;
}
.productDetailPage-btnArea-item .productDetailPage-btnArea-btn.icon-jpg {
  background: url(/html/template/default/assets/img/common/icon_jpg.svg) no-repeat left 17px center #F9F9F9;
  background-size: 17px auto;
}

/* productDetailPage-tagArea-list */
.productDetailPage-tagArea {
  width: 100%;
}
.ec-productRole .productDetailPage-tagArea-list,
.productDetailPage-tagArea-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none;
}
.ec-productRole .productDetailPage-tagArea-list-item,
.productDetailPage-tagArea-list-item {
  display: flex;
  align-items: center;
  width: 47px;
  padding: 0;
  margin: 0 3px 10px 0;
  background: transparent;
  border: none;
  border-radius: 0;
}
.ec-productRole .productDetailPage-tagArea-list-item.is-long,
.productDetailPage-tagArea-list-item.is-long {
  width: 144px;
}
.productDetailPage-tagArea-list-item img {
  display: block;
  width: 100%;
  height: auto;
}

/* productDetailPage-campaignArea */
.productDetailPage-campaignArea-title {
  display: block;
  width: 100%;
  padding: 9px 20px;
  margin: 0 0 20px;
  background-color: #F9F9F9;
  color: #343434;
  font-weight: normal;
  font-size: 20px;
}
.productDetailPage-campaignArea {
  width: 100%;
}
.productDetailPage-campaignArea-item {
  width: 100%;
}
.productDetailPage-campaignArea-item li {
  width: 20%;
}
.productDetailPage-campaignArea-item .productDetailPage-campaignArea-name {
  width: calc(60% - 50px);
}
.productDetailPage-campaignArea-item .productDetailPage-campaignArea-quantity {
  width: 50px;
}
.productDetailPage-campaignArea-detail .productDetailPage-campaignArea-quantity {
  text-align: center;
}
.productDetailPage-campaignArea .productDetailPage-campaignArea-header li {
  font-size: 14px;
}