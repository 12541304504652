@charset "UTF-8";
/* 共通 */
ul, li {
  list-style: none;
  margin: 0;
  padding: 0; }

a {
  display: block;
  width: 100%;
  text-decoration: none; }
  a:hover {
    opacity: 0.6;
    text-decoration: none; }

body {
  font-family: 'Noto Sans JP', sans-serif; }

/* common */
.ec-pageHeader h1 {
  padding: 0 0 10px;
  margin: 0 0 40px;
  border-bottom: 2px solid #EEEEEE;
  color: #525263;
  font-weight: normal;
  font-size: 32px; }

.ec-rectHeading h2 {
  padding: 9px 20px;
  margin: 0 0 20px;
  background-color: #F9F9F9;
  color: #343434;
  font-weight: normal;
  font-size: 20px; }

.commonText {
  margin-bottom: 10px; }

/* breadcrumb */
.commonBreadcrumb {
  width: 100%;
  padding: 0;
  margin: 0 0 20px;
  color: #343434; }

.commonBreadcrumb .commonBreadcrumb-item {
  display: inline;
  min-width: auto;
  color: #343434;
  font-weight: normal;
  font-size: 12px; }

.commonBreadcrumb .commonBreadcrumb-item a {
  display: inline;
  color: #3C82F0;
  font-weight: normal;
  font-size: 12px; }

.commonBreadcrumb .commonBreadcrumb-split {
  display: inline;
  font-weight: normal;
  font-size: 12px;
  margin: 0 3px; }

/* common > btn */
.ec-blockBtn--action,
.ec-blockBtn--cancel {
  font-family: "游ゴシック Medium","Yu Gothic Medium","游ゴシック体",YuGothic,sans-serif;
  font-weight: 500;
  font-size: 16px; }

.ec-blockBtn--action:hover,
.ec-blockBtn--cancel:hover {
  opacity: .8; }

.ec-blockBtn--action,
.ec-blockBtn--action:hover {
  background-color: #BF0404;
  border-color: #BF0404; }

.ec-blockBtn--cancel,
.ec-blockBtn--cancel:hover {
  background-color: #707070;
  border-color: #707070; }

/* modal */
.ec-modal-box .ec-inlineBtn--cancel,
.ec-modal-box .ec-inlineBtn--action {
  width: 180px;
  font-family: "游ゴシック Medium","Yu Gothic Medium","游ゴシック体",YuGothic,sans-serif;
  font-weight: 500; }

.ec-modal-box .ec-inlineBtn--cancel:hover,
.ec-modal-box .ec-inlineBtn--action:hover {
  opacity: .8; }

.ec-modal-box .ec-inlineBtn--cancel,
.ec-modal-box .ec-inlineBtn--cancel:hover {
  background-color: #707070;
  border-color: #707070; }

.ec-modal-box .ec-inlineBtn--action,
.ec-modal-box .ec-inlineBtn--action:hover {
  background-color: #BF0404;
  border-color: #BF0404; }

/* commonBtn */
.commonBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 160px;
  height: 45px;
  padding: 0 48px 0 0;
  margin: 0 auto;
  background: url(../img/common/right_arrow.svg) no-repeat right 22px center #fff;
  background-size: 20px auto;
  border: 1px solid #707070;
  color: #707070;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  position: relative;
  z-index: 1;
  cursor: pointer; }

.commonBtn:hover,
.commonBtn:focus {
  color: #707070;
  opacity: .8; }

.commonDotsList {
  list-style-type: none; }
  .commonDotsList li {
    box-sizing: border-box;
    width: 100%;
    padding-left: 20px;
    position: relative; }
    .commonDotsList li + li {
      margin-top: 5px; }
    .commonDotsList li::before {
      content: '';
      display: block;
      width: 3px;
      height: 3px;
      background-color: #525263;
      border-radius: 100%;
      position: absolute;
      top: 11px;
      left: 5px; }

.commonNumList {
  list-style-type: none;
  counter-reset: commonNum;
  padding: 0; }
  .commonNumList li {
    box-sizing: border-box;
    width: 100%;
    padding-left: 20px;
    position: relative; }
    .commonNumList li + li {
      margin-top: 5px; }
    .commonNumList li::before {
      counter-increment: commonNum;
      content: counter(commonNum) ". ";
      display: block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 0;
      left: 0; }

/* header */
.ec-layoutRole__header {
  width: 100%; }

@media only screen and (min-width: 768px) {
  .ec-headerSearch .ec-headerSearch__keyword input[type=search] {
    font-size: 18px; } }

/* is-hNavR__left タイトルロゴ */
.is-hNavR__left img {
  width: 182px;
  height: auto;
  display: block; }

/* is-header-block */
.is-header-block {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0;
  max-width: 1110px;
  width: 100%;
  margin: 0 auto; }

/* is-hNavR__right */
.ec-headerNaviRole .ec-headerNaviRole__right {
  width: 495px;
  padding-top: 12px; }

.is-hNavR__right._guide {
  min-width: 84px;
  padding-right: 39px; }
  .is-hNavR__right._guide a {
    display: block;
    width: 100%; }
  .is-hNavR__right._guide .is-icon {
    display: block;
    height: auto;
    margin: 0 auto 15px; }
    .is-hNavR__right._guide .is-icon img {
      width: 15px;
      display: block;
      height: auto;
      margin: 0 auto; }

.is-hNavR__right .ec-headerNav__item {
  padding: 0; }

.is-hNavR__right .ec-headerNav__item a,
.is-hNavR__right .is-cartNavi a {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 52px;
  padding: 0 15px;
  margin: 0;
  background: url(../img/common/icon_cart.png) no-repeat top center transparent;
  background-size: 30px auto;
  text-align: center;
  text-decoration: none;
  color: #707070; }

.is-hNavR__right .ec-headerNav__item.is-guide a {
  background-image: url(../img/common/icon_guide.png); }

.is-hNavR__right .ec-headerNav__item.is_logout a {
  background-image: url(../img/common/icon_logout.png); }

.is-hNavR__right .ec-headerNav__item.is_login a {
  background-image: url(../img/common/icon_login.png); }

.is-hNavR__right .ec-headerNav__item.is-heartIcon a {
  background-image: url(../img/common/icon_favo.png); }

.is-hNavR__right .ec-headerNav__item.is-userIcon a {
  background-image: url(../img/common/icon_mypage.png); }

.is-hNavR__right._cart .ec-cartNaviWrap .is-cartNavi a {
  padding-right: 5px;
  position: relative;
  background-position: top right 12px; }
  .is-hNavR__right._cart .ec-cartNaviWrap .is-cartNavi a .ec-cartNavi__badge {
    margin: 0;
    position: absolute;
    top: -10px;
    right: 0;
    left: auto; }

.is-hNavR__right._cart._link {
  font-size: 14px; }

/* 神社仏閣 */
.is-block__headerShrine {
  display: flex;
  align-items: center;
  width: calc(100% - 677px);
  height: 65px;
  padding: 0 0 0 17px;
  margin: 0 auto; }
  .is-block__headerShrine_Link {
    width: 140px; }
    .is-block__headerShrine_Link a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 30px;
      padding: 0;
      margin: 0;
      background-color: #F9F9F9;
      border-radius: 20px;
      border: solid 1px #ccc;
      color: #343434;
      text-align: center;
      font-size: 12px;
      text-decoration: none; }
  .is-block__headerShrine_userName {
    width: calc(100% - 140px);
    height: auto;
    padding: 0 20px 0 0;
    margin: 0;
    color: #707070;
    text-align: right;
    font-size: 14px; }
    .is-block__headerShrine_userName .companyName {
      padding: 0 8px 0 0; }

/* gNav */
.is-header-gNav {
  background: #343434;
  margin-bottom: 36px; }
  .is-header-gNav-list {
    display: flex;
    max-width: 1110px;
    margin: 0 auto;
    width: 100%; }
    .is-header-gNav-list::after {
      content: '';
      border-left: solid 1px #fff;
      display: inline-block;
      height: 22px;
      padding: 0;
      margin: auto; }
  .is-header-gNav-item {
    background: #343434;
    padding: 9px 0;
    margin: 0;
    text-align: center;
    width: calc(100% / 8);
    height: 40px; }
    .is-header-gNav-item .is-title {
      color: #fff;
      display: inline-block;
      justify-content: center;
      align-items: center;
      width: 100%;
      font-size: 14px;
      border-left: solid 1px #fff;
      text-decoration: none;
      padding: 0;
      margin: 0; }

/* footer */
.is-footerRole .is-footer__bg {
  background: #eee;
  display: flex; }

/* footer copyright */
.is-footerRole__copyright {
  font-size: 12px;
  color: #343434;
  text-align: center;
  width: 313px;
  margin: 0 auto;
  padding: 5px 0 3px;
  line-height: 1.7; }

.is-footerRole {
  border: none; }

.is-footerRole__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 664px;
  width: calc(100% - 493px);
  padding: 26px 0 21px; }
  .is-footerRole__inner .is-footerTitle__logo {
    max-width: 151px;
    width: 100%;
    margin: 0 auto; }
  .is-footerRole__inner .is-footerNavBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 493px; }
    .is-footerRole__inner .is-footerNavBlock__right {
      padding-left: 30px; }

/*   search_product.twig
------------------------ */
.is-headerSearch .is-hs__key {
  background-color: transparent; }
  .is-headerSearch .is-hs__key .ec-icon {
    height: auto; }

.is-headerSearch {
  float: none;
  width: 100%;
  background: #f9f9f9;
  height: 150px;
  padding: 0 10px;
  margin-bottom: 20px; }
  .is-headerSearch .is-hs__key {
    width: 100%;
    float: none;
    border: none;
    max-width: 325px;
    margin: 0 auto; }
    .is-headerSearch .is-hs__key:hover {
      opacity: .8; }
  .is-headerSearch .is-hs__keybtn {
    display: block;
    position: relative;
    right: unset;
    transform: none;
    background: #f19800;
    border-radius: 30px;
    color: #fff;
    text-align: center;
    height: auto;
    padding: 6px 0 5px;
    top: unset;
    width: 136px;
    margin: 0 auto; }
  .is-headerSearch .is-hs__key input.search-name {
    color: #525263;
    display: block;
    background-color: #fff;
    border: solid 2px #cccccc;
    border-radius: 0;
    margin: 0 auto 10px;
    width: 100%;
    height: 36px;
    padding: 10px 0 10px 13px; }
    .is-headerSearch .is-hs__key input.search-name::placeholder {
      color: #cfcfcf; }

.is-headerSearch__category img {
  width: 20px; }

.is-headerSearch__category .is-select_Search {
  background: none;
  border-radius: 0;
  border: 0;
  color: transparent;
  padding: 25px 0 12px; }

.is-select_Search::before {
  display: none !important; }

.is-hs__key {
  width: 100%; }
  .is-hs__key .is-input {
    width: 100%; }

/*   category_nav_pc.twig
------------------------ */
.is-asidNav__title {
  background: #707070;
  color: #fff;
  font-size: 14px;
  opacity: .9;
  padding: 3.5px 0;
  text-align: center;
  position: relative; }

.is-cateNav {
  display: block !important;
  width: 100%;
  padding: 0;
  margin-bottom: 20px; }
  .is-cateNav .is-asidNav__title {
    cursor: pointer; }
    .is-cateNav .is-asidNav__title::after {
      content: '';
      display: block;
      width: 30px;
      height: 100%;
      background: url(../img/common/icon_open_wh.svg) no-repeat center center;
      background-size: 15px auto;
      position: absolute;
      top: 0;
      right: 0; }
    .is-cateNav .is-asidNav__title.is-active::after {
      background-image: url(../img/common/icon_close_wh.svg); }

.categoryNav-inner .categoryNav-list {
  display: block;
  width: 100%; }
  .categoryNav-inner .categoryNav-list li {
    display: block; }

.ec-itemNav__nav li a,
.ec-itemNav__nav li ul li a {
  font-weight: bold; }

.categoryNav-inner {
  display: none; }
  .categoryNav-inner.first-open {
    display: block; }
  .categoryNav-inner .categoryNav-list .categoryNav-list-item:hover {
    opacity: 0.6; }
  .categoryNav-inner .categoryNav-list .categoryNav-list-item span {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 60px;
    text-align: left;
    font-size: 16px;
    line-height: 16px;
    color: #585858;
    padding: 20px 50px 23px 12px;
    background-color: transparent;
    background-image: no-repeat left center;
    background-size: 40px auto;
    border-bottom: 1px solid #eee;
    cursor: pointer;
    position: relative; }
    .categoryNav-inner .categoryNav-list .categoryNav-list-item span::after {
      content: '';
      display: block;
      width: 30px;
      height: 100%;
      background: url(../img/common/icon_open.svg) no-repeat center center;
      background-size: 15px auto;
      position: absolute;
      top: 0;
      right: 0; }
    .categoryNav-inner .categoryNav-list .categoryNav-list-item span.is-active::after {
      background-image: url(../img/common/icon_close.svg); }
    .categoryNav-inner .categoryNav-list .categoryNav-list-item span a {
      display: block;
      border: none;
      background: transparent;
      text-align: left;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
      position: relative;
      cursor: pointer; }
    .categoryNav-inner .categoryNav-list .categoryNav-list-item span:hover {
      opacity: 0.6; }
  .categoryNav-inner .categoryNav-list .categoryNav-list-item ul {
    min-width: auto;
    padding: 0 0 4px 26px;
    position: relative;
    left: 0; }
    .categoryNav-inner .categoryNav-list .categoryNav-list-item ul li {
      overflow: visible;
      height: auto; }
      .categoryNav-inner .categoryNav-list .categoryNav-list-item ul li span {
        display: block;
        border: none;
        background: transparent;
        text-align: left;
        font-size: 14px;
        font-weight: 300;
        color: #333333;
        position: relative;
        cursor: pointer; }
        a .categoryNav-inner .categoryNav-list .categoryNav-list-item ul li span:hover {
          opacity: 0.6; }

.categoryNav-list-item a {
  padding: 20px 0 23px 12px; }

/* asidNav-sceneArea */
.asidNav-sceneArea {
  width: 100%; }
  .asidNav-sceneArea-list {
    width: 100%;
    border: 1px solid #CCCCCC;
    border-bottom: none; }
    .asidNav-sceneArea-list-item {
      width: 100%;
      border-bottom: 1px solid #CCCCCC; }
      .asidNav-sceneArea-list-item .is-inner {
        display: block;
        width: 100%;
        padding: 12px 5px;
        text-align: center;
        color: #343434;
        font-size: 14px;
        line-height: 1.5; }

/*   check_product.twig
------------------------ */
.is-checProductRole {
  margin-bottom: 23px; }

.ec-checkProductRole__listItem {
  padding: 25px 12px 15px !important; }
  .ec-checkProductRole__listItem .item_photo {
    width: 100%;
    display: inline-flex; }
    .ec-checkProductRole__listItem .item_photo img {
      max-width: 70px;
      height: 69px; }
  .ec-checkProductRole__listItem.is-checkProductRole__inner {
    display: block;
    width: 100%; }
  .ec-checkProductRole__listItem .ec-checkProductRole__listItemTitle {
    color: #343434;
    line-height: 1.5; }
  .ec-checkProductRole__listItem .is-checkProductRole__listItemTitleTop {
    margin-bottom: 20px; }

/*   apg_sc_two_month_calendar.twig
-------------------------------------- */
.is-topCalendar {
  border: solid 1px #eee; }
  .is-topCalendar table.apg_sc_table {
    width: 100%;
    height: auto;
    display: inline-table;
    text-align: center; }
    .is-topCalendar table.apg_sc_table .apg_sc_header {
      text-align: center;
      padding: 10px 0;
      background: #F9F9F9; }

.is-apg_sc_weekList th {
  border-top: 1px solid #eee;
  text-align: center;
  background: #F9F9F9;
  padding: 5px 0px; }
  .is-apg_sc_weekList th + th {
    border-left: 1px solid #eee; }

.is-apg_sc_daysList td {
  color: #707070;
  border-top: 1px solid #eee;
  padding: 5px; }
  .is-apg_sc_daysList td + td {
    border-left: 1px solid #eee; }
  .is-apg_sc_daysList td.apg_sc_sunday, .is-apg_sc_daysList td.apg_sc_holiday, .is-apg_sc_daysList td.apg_sc_saturday {
    background: rgba(191, 4, 4, 0.3); }
  .is-apg_sc_daysList td.apg_sc_non_day {
    background: #fff; }

/*   index.twig
------------------------ */
/*  メインビュー スライダー */
.slick-slide img {
  padding: 0 0 0 25px; }

/*   top_index.twig
------------------------ */
.is-indexRole {
  background: none; }

.is-index__list {
  border: none;
  padding: 0;
  margin: 0 12.5px !important;
  display: block;
  text-align: center; }
  .is-index__list:first-child {
    margin-left: 0 !important; }
  .is-index__list:last-child {
    margin-right: 0 !important; }
  .is-index__list .is-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 84px;
    padding: 0 10px;
    border: solid 1px #ccc;
    color: #343434; }

/*   news.twig
------------------------ */
.ec-newsRole {
  padding-top: 10px;
  margin-bottom: 70px; }
  .ec-newsRole .ec-newsRole__newsItem {
    width: 100%;
    padding: 0;
    margin: 0;
    border-bottom: solid 1px #B5B5B5; }
    .ec-newsRole .ec-newsRole__newsItem:hover {
      opacity: .8; }
  .ec-newsRole .ec-newsRole__newsColumn {
    display: flex;
    width: calc(100% - 145px);
    min-width: auto; }
  .ec-newsRole .ec-newsRole__newsClose {
    width: 30px !important; }
    .ec-newsRole .ec-newsRole__newsClose .ec-newsRole__newsCloseBtn {
      display: block;
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      background: transparent;
      border-radius: 0;
      position: relative;
      right: auto; }
      .ec-newsRole .ec-newsRole__newsClose .ec-newsRole__newsCloseBtn::before, .ec-newsRole .ec-newsRole__newsClose .ec-newsRole__newsCloseBtn::after {
        content: '';
        display: block;
        width: 15px;
        height: 1px;
        background: #707070;
        position: absolute;
        top: calc(50% - 1px);
        left: 0; }
      .ec-newsRole .ec-newsRole__newsClose .ec-newsRole__newsCloseBtn::after {
        transform: rotate(-90deg); }
      .ec-newsRole .ec-newsRole__newsClose .ec-newsRole__newsCloseBtn i {
        display: none !important; }
  .ec-newsRole .ec-newsRole__newsDate,
  .ec-newsRole .ec-newsRole__newsTitle {
    color: #333;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.6;
    padding: 18px 0;
    margin: 0; }
  .ec-newsRole .ec-newsRole__newsDate {
    display: block;
    width: 145px; }
  .ec-newsRole .ec-newsRole__newsTitle {
    width: calc(100% - 30px);
    padding-right: 10px; }
  .ec-newsRole .ec-newsRole__newsDescription {
    width: 100%;
    padding: 0 0 20px;
    margin: 0 !important;
    line-height: 1.6; }
  .ec-newsRole .ec-newsRole-bottom {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 40px;
    margin: 0; }

/* topPage */
.topPage-recommendArea {
  width: 100%; }
  .topPage-recommendArea-inner {
    width: 100%; }
    .topPage-recommendArea-inner.is-login {
      display: flex;
      flex-wrap: wrap; }
  .topPage-recommendArea-item {
    width: 100%;
    padding: 0;
    margin: 0 0 50px; }
    .is-login .topPage-recommendArea-item {
      display: flex;
      flex-wrap: wrap;
      width: calc(33.3% - 33.3px);
      margin: 0 50px 0 0; }
      .is-login .topPage-recommendArea-item:nth-child(3n) {
        margin-right: 0; }
  .topPage-recommendArea-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 75px);
    padding: 0;
    margin: 0; }
    .topPage-recommendArea-main-title {
      display: block;
      padding: 8px 5px 9px;
      margin: 0;
      background-color: #767DBB;
      color: #fff;
      text-align: center; }
      .is-purchase .topPage-recommendArea-main-title {
        background-color: #F19800; }
      .is-favorite .topPage-recommendArea-main-title {
        background-color: #95C228; }
      .topPage-recommendArea-main-title .is-ja,
      .topPage-recommendArea-main-title .is-en {
        display: block;
        width: 100%;
        text-align: center;
        font-weight: normal; }
      .topPage-recommendArea-main-title .is-ja {
        font-size: 20px; }
      .topPage-recommendArea-main-title .is-en {
        font-size: 12px;
        line-height: 1; }
    .topPage-recommendArea-main-list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;
      padding: 30px;
      margin: 0;
      border: 1px solid #CCCCCC;
      border-top: none; }
      .is-logout .topPage-recommendArea-main-list {
        padding: 26px; }
      .topPage-recommendArea-main-list-item {
        width: 100%;
        padding: 0;
        margin: 0; }
        .is-login .is-recommend .topPage-recommendArea-main-list-item {
          display: none; }
          .is-login .is-recommend .topPage-recommendArea-main-list-item:first-child, .is-login .is-recommend .topPage-recommendArea-main-list-item:nth-child(2) {
            display: block; }
        .topPage-recommendArea-main-list-item + .topPage-recommendArea-main-list-item {
          margin-top: 50px; }
        .is-logout .topPage-recommendArea-main-list-item {
          width: calc(25% - 45px);
          margin: 0 60px 0 0; }
          .is-logout .topPage-recommendArea-main-list-item:nth-child(4n) {
            margin-right: 0; }
          .is-logout .topPage-recommendArea-main-list-item + .topPage-recommendArea-main-list-item {
            margin-top: 30px; }
        .topPage-recommendArea-main-list-item .is-inner {
          display: block;
          width: 78.6%;
          margin: 0 auto; }
        .topPage-recommendArea-main-list-item-img {
          display: block;
          width: 100%;
          height: 0;
          padding-top: 100%;
          margin-bottom: 10px;
          position: relative; }
          .topPage-recommendArea-main-list-item-img-inner {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0; }
          .topPage-recommendArea-main-list-item-img img {
            display: block;
            width: auto;
            max-width: 100%;
            height: auto;
            max-height: 100%;
            padding: 0;
            object-fit: contain; }
        .topPage-recommendArea-main-list-item .is-name {
          display: block;
          width: 100%;
          color: #343434;
          text-align: center;
          font-size: 14px;
          line-height: 1.6; }
  .topPage-recommendArea-bottom {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 73px; }

.industryPage-bnrArea {
  display: flex;
  flex-wrap: wrap;
  width: 100%; }
  .industryPage-bnrArea-item {
    width: calc(33.3% - 24px);
    margin: 0 36px 40px 0; }
    .industryPage-bnrArea-item:first-child {
      width: 100%;
      margin-right: 0; }
    .industryPage-bnrArea-item:nth-child(4) {
      margin-right: 0; }
    .industryPage-bnrArea-item:nth-child(3n + 4) {
      margin-right: 0; }
    .industryPage-bnrArea-item .is-inner {
      display: block;
      width: 100%; }
      .industryPage-bnrArea-item .is-inner img {
        display: block;
        width: 100%;
        height: auto; }

/* product page */
.productDetailPage .productDetailPage-leftBlock {
  width: 100%; }

@media only screen and (min-width: 768px) {
  .productDetailPage .productDetailPage-leftBlock {
    width: 340px; } }

.productDetailPage .productDetailPage-rightBlock {
  width: 100%; }

@media only screen and (min-width: 768px) {
  .productDetailPage .productDetailPage-rightBlock {
    width: calc(100% - 340px);
    padding-left: 34px; } }

.productDetailPage .ec-productRole__profile {
  margin: 0; }

.productDetailPage .ec-productRole__title .ec-headingTitle {
  margin-bottom: 10px;
  color: #343434;
  font-size: 28px;
  line-height: 1.5; }

.productDetailPage .ec-productRole__description {
  margin-bottom: 24px;
  color: #555555;
  font-size: 14px;
  line-height: 1.5; }

/* productDetailPage-leftBlock */
.productDetailPage-leftBlock .item_visual {
  margin-bottom: 34px; }

.productListPage-mainArea .productDetailPage-mainImg,
.productDetailPage-leftBlock .productDetailPage-mainImg {
  display: block;
  width: 100%;
  height: 0;
  padding-top: 100%;
  position: relative; }

.productListPage-mainArea .productDetailPage-mainImg-inner,
.productDetailPage-leftBlock .productDetailPage-mainImg-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0; }

.productListPage-mainArea .productDetailPage-mainImg img,
.productDetailPage-leftBlock .productDetailPage-mainImg img {
  display: block;
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  padding: 0;
  -o-object-fit: contain;
  object-fit: contain; }

.productDetailPage-leftBlock .ec-sliderItemRole .slideThumb {
  width: calc(33.3% - 13.3px);
  margin: 0 20px 20px 0;
  border: 1px solid #CCCCCC; }

.productDetailPage-leftBlock .ec-sliderItemRole .slideThumb:nth-child(3n) {
  margin-right: 0; }

/* productDetailPage > productDetailPage-priceArea */
.productDetailPage-priceArea {
  width: 100%;
  padding: 19px 0 17px;
  margin-bottom: 25px;
  border-top: 1px solid #C9C9C9;
  border-bottom: 1px solid #C9C9C9;
  font-size: 16px; }

/* productDetailPage > productDetailPage-cartArea */
.productDetailPage-cartArea {
  width: 100%;
  margin-bottom: 40px; }

.productDetailPage-cartArea-unit {
  margin-bottom: 25px;
  color: #525263;
  font-size: 14px; }

.productDetailPage .ec-productRole__price {
  padding: 0;
  border: none;
  color: #525263;
  font-size: 16px; }

.productDetailPage .ec-productRole__priceRegularPrice {
  font-family: 'Noto Serif JP', serif;
  color: #525263;
  font-weight: normal;
  font-size: 16px;
  line-height: normal; }

.productDetailPage .ec-productRole__priceRegularTax {
  margin: 0;
  font-size: 12px; }

.productDetailPage .ec-productRole__price .ec-price__price {
  padding: 0;
  font-family: 'Noto Serif JP', serif;
  color: #BF0404;
  font-size: 29px; }

.productDetailPage .ec-productRole__price .ec-price__tax {
  color: #BF0404;
  font-size: 16px; }

.productDetailPage .ec-productRole__actions {
  padding: 10px 0; }

.productDetailPage .ec-productRole__actions .ec-numberInput .is-pre {
  margin-right: 4px; }

.productDetailPage .ec-productRole__actions .ec-numberInput .is-unit {
  margin-left: 5px; }

.productDetailPage .ec-productRole__actions .ec-numberInput input[type='number'] {
  margin: 0; }

.productDetailPage .ec-productRole__btn {
  margin: 20px 0 0; }

/* productDetailPage > productDetailPage-infoArea */
.productDetailPage-infoArea {
  width: 100%;
  margin-bottom: 22px;
  border-bottom: 1px dotted #C9C9C9; }

.productDetailPage-infoArea + .productDetailPage-infoArea {
  margin-top: 32px; }

.productDetailPage-infoArea-item {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  margin: 0;
  border-top: 1px dotted #C9C9C9; }

.productDetailPage-infoArea-term {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  background-color: #F9F9F9;
  color: #555555;
  text-align: center;
  font-weight: normal;
  font-size: 16px; }

.productDetailPage-infoArea-description {
  width: calc(100% - 160px);
  padding: 14px 0 10px 18px;
  margin: 0;
  color: #555555;
  font-size: 14px;
  line-height: 1.6; }

/* productDetailPage > productDetailPage-btnArea */
.productDetailPage-btnArea {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 10px); }

.productDetailPage-btnArea-item {
  width: auto;
  max-width: calc(50% - 10px);
  min-width: 160px;
  margin: 0 10px 10px 0; }

.productDetailPage-btnArea-item .productDetailPage-btnArea-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 35px;
  padding: 5px 30px 5px 50px;
  background: url(/html/template/default/assets/img/common/icon_pdf.png) no-repeat left 18px center #F9F9F9;
  background-size: 18px auto;
  border: 1px solid #CCCCCC;
  border-radius: 36px;
  overflow: hidden;
  color: #343434;
  font-size: 13px;
  line-height: 1.3; }

.productDetailPage-btnArea-item .productDetailPage-btnArea-btn.icon-xls {
  background: url(/html/template/default/assets/img/common/icon_xls.svg) no-repeat left 17px center #F9F9F9;
  background-size: 18px auto; }

.productDetailPage-btnArea-item .productDetailPage-btnArea-btn.icon-jpg {
  background: url(/html/template/default/assets/img/common/icon_jpg.svg) no-repeat left 17px center #F9F9F9;
  background-size: 17px auto; }

/* productDetailPage-tagArea-list */
.productDetailPage-tagArea {
  width: 100%; }

.ec-productRole .productDetailPage-tagArea-list,
.productDetailPage-tagArea-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  margin: 0;
  border: none; }

.ec-productRole .productDetailPage-tagArea-list-item,
.productDetailPage-tagArea-list-item {
  display: flex;
  align-items: center;
  width: 47px;
  padding: 0;
  margin: 0 3px 10px 0;
  background: transparent;
  border: none;
  border-radius: 0; }

.ec-productRole .productDetailPage-tagArea-list-item.is-long,
.productDetailPage-tagArea-list-item.is-long {
  width: 144px; }

.productDetailPage-tagArea-list-item img {
  display: block;
  width: 100%;
  height: auto; }

/* productDetailPage-campaignArea */
.productDetailPage-campaignArea-title {
  display: block;
  width: 100%;
  padding: 9px 20px;
  margin: 0 0 20px;
  background-color: #F9F9F9;
  color: #343434;
  font-weight: normal;
  font-size: 20px; }

.productDetailPage-campaignArea {
  width: 100%; }

.productDetailPage-campaignArea-item {
  width: 100%; }

.productDetailPage-campaignArea-item li {
  width: 20%; }

.productDetailPage-campaignArea-item .productDetailPage-campaignArea-name {
  width: calc(60% - 50px); }

.productDetailPage-campaignArea-item .productDetailPage-campaignArea-quantity {
  width: 50px; }

.productDetailPage-campaignArea-detail .productDetailPage-campaignArea-quantity {
  text-align: center; }

.productDetailPage-campaignArea .productDetailPage-campaignArea-header li {
  font-size: 14px; }

/* productListPage > productListPage-searcArea */
.productListPage-searcArea {
  width: 100%; }

.productListPage-searcArea .ec-searchnavRole__infos {
  width: 100%;
  height: 50px;
  padding: 0;
  margin: 0 0 40px;
  border: none; }

.productListPage-searcArea .ec-searchnavRole__infos .ec-searchnavRole__counter {
  display: flex;
  align-items: center;
  width: calc(100% - 260px); }

.productListPage-searcArea .ec-searchnavRole__infos .ec-searchnavRole__counter .productListPage-resultNote {
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 14px; }

.productListPage-searcArea .ec-searchnavRole__infos .ec-searchnavRole__actions {
  width: 260px; }

.productListPage-searcArea .ec-searchnavRole__infos .ec-searchnavRole__actions .ec-select {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0; }

.productListPage-searcArea .ec-searchnavRole__infos .ec-searchnavRole__actions .ec-select .disp-number,
.productListPage-searcArea .ec-searchnavRole__infos .ec-searchnavRole__actions .ec-select .order-by {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  height: 50px;
  padding: 0 35px 0 20px;
  background: url(/html/template/default/assets/img/common/icon_select.svg) no-repeat right 15px center #fff;
  background-size: 10px auto;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  font-size: 13px;
  line-height: normal;
  cursor: pointer; }

.productListPage-searcArea .ec-searchnavRole__infos .ec-searchnavRole__actions .ec-select .disp-number {
  width: 100px; }

.productListPage-searcArea .ec-searchnavRole__infos .ec-searchnavRole__actions .ec-select .order-by {
  width: calc(100% - 110px); }

/* productListPage > productListPage-mainArea */
.productListPage-mainArea {
  width: 100%;
  padding-bottom: 80px;
  position: relative; }

.productListPage-mainArea-list {
  width: 100%;
  margin: 0%; }

.productListPage-mainArea .productListPage-mainArea-list .productListPage-mainArea-list-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(25% - 50.25px);
  padding: 0;
  margin: 0 67px 60px 0; }

.productListPage-mainArea .productListPage-mainArea-list .productListPage-mainArea-list-item:nth-child(4n) {
  margin-right: 0; }

.productListPage-mainArea .ec-shelfGrid__item-image {
  height: auto;
  margin-bottom: 10px; }

.productListPage-mainArea .productListPage-mainArea-productInfo {
  display: block;
  padding-bottom: 15px; }

.productListPage-mainArea .productListPage-productInfo-text {
  font-size: 14px;
  line-height: 2; }

.productListPage-mainArea-productForm .ec-select {
  margin-bottom: 7px; }

.productListPage-mainArea-productForm .ec-numberInput {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }

.productListPage-mainArea-productForm .ec-numberInput input[type='number'] {
  width: 85px;
  height: 24px;
  padding: 3px 10px 0;
  margin: 0;
  border: 1px solid #707070;
  border-radius: 0;
  font-size: 14px; }

.productListPage-mainArea-productForm .ec-numberInput .btn-reserved {
  display: inline-block;
  width: 50px;
  height: 24px;
  padding: 0;
  background-color: #707070;
  border: none;
  color: #fff;
  text-align: center;
  font-weight: normal;
  font-size: 14px;
  line-height: normal;
  vertical-align: baseline; }

.productListPage-mainArea-productForm .ec-numberInput .btn-reserved:hover {
  opacity: .8; }

/* .productListPage-favArea */
.productListPage-favArea {
  width: 20px; }
  .productListPage-favArea-inner {
    display: block;
    width: 100%;
    height: 100%;
    background: url(../icon/heart_red_line.svg) no-repeat center center;
    background-size: 100% auto;
    cursor: pointer; }
    .productListPage-favArea-inner:hover {
      opacity: .8; }
    .productListPage-favArea-inner.is-active {
      background-image: url(../icon/heart_red.svg); }

.productListPage-cartBtnArea {
  width: 280px;
  position: fixed;
  bottom: 80px;
  right: 30px; }

.productListPage-cartBtnArea .ec-blockBtn--action,
.productListPage-cartBtnArea .ec-blockBtn--cancel {
  width: 100%;
  height: 60px;
  font-weight: 500;
  font-size: 16px; }

.productListPage-cartBtnArea .ec-blockBtn--action:hover,
.productListPage-cartBtnArea .ec-blockBtn--cancel:hover {
  opacity: .8; }

.productListPage-cartBtnArea .ec-blockBtn--action,
.productListPage-cartBtnArea .ec-blockBtn--action:hover {
  background-color: #BF0404;
  border-color: #BF0404; }

.productListPage-cartBtnArea .ec-blockBtn--cancel,
.productListPage-cartBtnArea .ec-blockBtn--cancel:hover {
  background-color: #707070;
  border-color: #707070; }

.productListPage-mainArea .ec-pagerRole .ec-pager {
  text-align: left; }

/* productListPage-catArea */
.productListPage-catArea {
  width: 100%;
  margin-bottom: 20px; }

.productListPage-catArea-title {
  display: block;
  width: 100%;
  padding: 10px 35px 10px 15px;
  background-color: #343434;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  position: relative;
  cursor: pointer; }

.productListPage-catArea-title:hover {
  opacity: .8; }

.productListPage-catArea-title::before,
.productListPage-catArea-title::after {
  content: '';
  display: block;
  width: 16px;
  height: 1px;
  background-color: #fff;
  position: absolute; }

.productListPage-catArea-title::before {
  top: 18px;
  right: 10px; }

.productListPage-catArea-title::after {
  transform: rotate(90deg);
  top: 18px;
  right: 10px;
  opacity: 1;
  transition: opacity .3s ease; }

.productListPage-catArea-title.is-open::after {
  opacity: 0; }

.productListPage-catArea-main {
  width: 100%; }

.productListPage-catArea-main-inner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 20px 17px 0 20px; }

.productListPage-catArea-main-img {
  display: block;
  width: calc(100% - 440px);
  padding: 0;
  margin: 0; }

.productListPage-catArea-main-img img {
  display: block;
  width: 100%;
  height: auto; }

.productListPage-catArea-main-body {
  width: 440px;
  padding: 15px 0 0 23px;
  font-size: 14px;
  line-height: 1.8; }

.productListPage-catArea-main-body p {
  font-size: 14px;
  line-height: 1.8; }

.productListPage-catArea-main-btn {
  width: 100%;
  padding: 13px 0 0;
  margin: 0; }

/* news page */
.newsPage-main {
  width: 100%;
  padding: 0 20px;
  margin-bottom: 40px; }

.newsPage-main-item {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #CCCCCC;
  color: #343434;
  font-size: 14px; }

.newsPage-main-date {
  width: 148px;
  padding: 18px 5px 18px 0;
  font-weight: normal;
  line-height: 1.8; }

.newsPage-main-body {
  width: calc(100% - 148px);
  padding: 18px 0;
  line-height: 1.8; }

.newsPage-pager .ec-pager {
  width: 100%;
  padding: 20px 0;
  text-align: left; }

/* cart page */
.ec-progress .ec-progress__item:after {
  background-color: #CCCCCC; }

.ec-progress .ec-progress__number {
  font-family: "游ゴシック Medium","Yu Gothic Medium","游ゴシック体",YuGothic,sans-serif;
  font-weight: 500;
  background-color: #707070; }

.ec-progress .is-complete .ec-progress__number {
  background-color: #767DBB;
  font-family: 'Noto Sans JP', sans-serif; }

.ec-progress .ec-progress__label {
  font-family: 'Noto Sans JP', sans-serif;
  color: #343434;
  font-weight: 500; }

.ec-progress .is-complete .ec-progress__label {
  color: #767DBB; }

.ec-cartRole .ec-cartRole__totalText {
  margin-bottom: 45px; }

.ec-cartRole .ec-cartRole__totalText p {
  color: #343434;
  font-size: 16px; }

.ec-cartRole .ec-cartRole__totalText p strong {
  color: #BF0404; }

#form_cart {
  width: 100%;
  margin: 0 0 40px; }

.ec-cartRole .ec-cartRole__cart {
  margin: 0; }

.ec-cartTable {
  padding-right: 27px; }

.productDetailPage-campaignArea-header li,
.ec-cartHeader .ec-cartHeader__label {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 52px;
  padding: 6px 0;
  background-color: #343434;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.3;
  overflow: visible; }

.productDetailPage-campaignArea-header li.bg-yellow,
.ec-cartHeader .ec-cartHeader__label.bg-yellow {
  background-color: #F19800; }

.ec-cartRow {
  position: relative; }

.productDetailPage-campaignArea-item li,
.ec-cartHeader li,
.ec-cartRow li {
  border-left: 1px dotted #ccc; }

.productDetailPage-campaignArea-detail li,
.ec-cartRow li {
  padding: 25px 8px;
  border-bottom: 1px dotted #ccc; }

.productDetailPage-campaignArea-header li:first-child,
.ec-cartHeader li:first-child {
  border-left: none; }

.productDetailPage-campaignArea-detail li:last-child,
.ec-cartRow li:last-child {
  border-right: 1px dotted #ccc; }

.productDetailPage-campaignArea-item,
.cartPage-table {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0; }

.cartPage-table .cartPage-table-delete {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 100%;
  padding: 0;
  border: none;
  position: absolute;
  top: 0;
  right: -27px; }

.cartPage-table .cartPage-table-delete .ec-icon {
  display: block;
  width: 21px;
  height: 21px;
  background: url(/html/template/default/assets/icon/cross-white.svg) no-repeat center center #CCCCCC;
  background-size: 11px auto;
  border-radius: 100%;
  text-indent: -1000px;
  overflow: hidden; }

.cartPage-table .cartPage-table-no {
  width: 100px; }

.cartPage-table .cartPage-table-mysku {
  width: 64px; }

.cartPage-table.ec-cartRow .cartPage-table-mysku {
  text-align: center; }

.cartPage-table .cartPage-table-name {
  width: calc(100% - 523px); }

.cartPage-table.ec-cartRow .cartPage-table-name {
  padding: 25px 8px; }

.cartPage-table.ec-cartRow .cartPage-table-name a {
  color: #343434; }

.cartPage-table .cartPage-table-count {
  width: 97px; }

.cartPage-table .cartPage-table-count .cartPage-table-count-inner {
  display: block;
  width: 100%;
  padding: 1px 6px;
  border: 1px solid #CCCCCC;
  text-align: right;
  font-size: 13px;
  line-height: normal; }

.cartPage-table .cartPage-table-unit {
  width: 55px; }

.cartPage-table.ec-cartRow .cartPage-table-unit {
  text-align: center; }

.cartPage-table .cartPage-table-orderunit {
  width: 50px; }

.cartPage-table.ec-cartRow .cartPage-table-orderunit {
  text-align: center; }

.cartPage-table .cartPage-table-price {
  width: 103px; }

.cartPage-table.ec-cartRow .cartPage-table-price {
  text-align: right; }

.cartPage-table .cartPage-table-total {
  width: 114px; }

.cartPage-table.ec-cartRow .cartPage-table-total {
  text-align: right;
  font-weight: 500;
  font-size: 16px; }

/* cartPage-actionArea */
.ec-cartRole .cartPage-actionArea {
  width: 100%;
  padding: 0 42px 0 0;
  margin: 0; }

.cartPage-actionArea-note {
  margin-bottom: 18px;
  color: #343434;
  text-align: right;
  font-weight: normal;
  font-size: 12px; }

.ec-cartRole .cartPage-totalArea {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 30px 0 20px;
  font-weight: normal; }

.cartPage-totalArea-reCalc {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 40px;
  padding: 0;
  margin: 0 46px 0 0;
  background: #F9F9F9;
  border: 1px solid #CCCCCC;
  border-radius: 20px;
  color: #343434;
  font-weight: normal;
  font-size: 14px;
  line-height: 0;
  cursor: pointer; }

.cartPage-totalArea-reCalc:hover {
  opacity: .8; }

.cartPage-totalArea-main {
  width: auto;
  font-weight: 500;
  font-size: 14px; }

.ec-cartRole .cartPage-totalArea-main .ec-cartRole__totalAmount {
  color: #BF0404;
  font-size: 24px; }

.cartPage-actionArea-btnArea {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%; }

.cartPage-actionArea-btnArea .ec-blockBtn--action,
.cartPage-actionArea-btnArea .ec-blockBtn--cancel {
  width: 350px;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 14px; }

/* shopping page */
.shoppingPage-progress {
  margin-bottom: 50px; }

/* shoppingPage-orderArea */
.shoppingPage-orderArea {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  margin: 0; }

/* ec-orderRole__detail */
.shoppingPage-orderArea .ec-orderRole__detail {
  width: calc(100% - 331px);
  padding: 0 47px 0 0;
  margin: 0; }

.shoppingPage-orderNumber {
  display: flex;
  align-items: center;
  width: 100%;
  height: 33px;
  padding: 0;
  margin: 0 0 40px; }

.shoppingPage-orderNumber-term {
  width: 114px;
  padding: 0 0 0 8px;
  font-weight: normal;
  font-size: 16px; }

.shoppingPage-orderNumber-num {
  display: flex;
  align-items: center;
  width: 200px;
  height: 100%;
  padding: 0 5px;
  border: 1px solid #707070; }

#page_shopping_confirm .shoppingPage-orderNumber-num {
  border: none; }

.ec-orderRole__detail .ec-orderDelivery {
  margin-bottom: 50px; }

.shoppingPage-addressBtnArea {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0 0 25px; }

.shoppingPage-addressBtnArea-item {
  height: 40px;
  padding: 0;
  margin: 0 10px 0 0;
  line-height: normal; }

.ec-orderDelivery .shoppingPage-addressBtnArea-item.ec-orderDelivery__change {
  display: block;
  position: static;
  top: unset;
  right: unset; }

.shoppingPage-addressBtnArea-item button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 100%;
  padding: 0 30px;
  margin: 0;
  background-color: #F9F9F9;
  border: 1px solid #CCCCCC;
  border-radius: 20px;
  line-height: 0; }

.shoppingPage-addressBtnArea-item button:hover {
  background-color: #F9F9F9;
  opacity: .8; }

.shoppingPage-orderArea .ec-orderDelivery__address {
  width: 100%;
  margin: 0 0 26px; }

.shoppingPage-orderArea .ec-orderDelivery__address p {
  font-size: 16px;
  line-height: 1.8; }

.shoppingPage-orderArea .ec-orderDelivery__address .is-bold {
  font-weight: 500; }

.shoppingPage-orderArea .shoppingPage-delivSelectArea {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  margin: 0; }

.shoppingPage-orderArea .shoppingPage-delivSelectArea-term {
  display: flex;
  align-items: center;
  width: 120px;
  height: 50px;
  padding: 0 10px 0 0;
  margin: 0;
  color: #525263;
  font-weight: 700;
  font-size: 16px; }

.shoppingPage-orderArea .shoppingPage-delivSelectArea-main {
  min-width: calc(100% - 120px);
  padding: 0;
  margin: 0;
  border: none; }

#page_shopping_confirm .shoppingPage-orderArea .shoppingPage-delivSelectArea-main {
  display: flex;
  align-items: center;
  height: 50px; }

.shoppingPage-orderArea .shoppingPage-delivSelectArea-main .ec-select {
  margin: 0 10px 0 0; }

.shoppingPage-orderArea .shoppingPage-delivSelectArea-main .ec-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  width: auto;
  height: 50px;
  padding: 0 35px 0 20px;
  background: url(/html/template/default/assets/img/common/icon_select.svg) no-repeat right 15px center #fff;
  background-size: 10px auto;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  color: #343434;
  font-size: 13px;
  line-height: normal;
  cursor: pointer; }

.shoppingPage-orderArea .shoppingPage-delivSelectArea-main .ec-select__delivery select {
  width: 150px; }

.shoppingPage-orderArea .shoppingPage-delivSelectArea-main .ec-select__time select {
  width: 110px; }

.shoppingPage-orderArea .ec-orderPayment {
  width: 100%;
  margin-bottom: 50px; }

.shoppingPage-orderArea .ec-orderPayment .shoppingPage-radioArea {
  width: 100%; }

.shoppingPage-orderArea .ec-orderPayment .shoppingPage-radioArea input[type=radio] {
  display: none; }

.shoppingPage-orderArea .ec-orderPayment .shoppingPage-radioArea label {
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  cursor: pointer; }

.shoppingPage-orderArea .ec-orderPayment .shoppingPage-radioArea label span {
  display: block;
  width: 100%;
  padding: 0 0 0 34px;
  font-size: 16px;
  line-height: 1.8;
  position: relative; }

.shoppingPage-orderArea .ec-orderPayment .shoppingPage-radioArea label span::before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  background-color: #F9F9F9;
  border: 1px solid #CCCCCC;
  border-radius: 100%;
  position: absolute;
  top: 5px;
  left: 0; }

.shoppingPage-orderArea .ec-orderPayment .shoppingPage-radioArea input[type=radio]:checked + label span::before {
  border-color: #767DBB; }

.shoppingPage-orderArea .ec-orderPayment .shoppingPage-radioArea label span::after {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  background-color: #767DBB;
  border-radius: 100%;
  position: absolute;
  top: 9px;
  left: 4px;
  opacity: 0; }

.shoppingPage-orderArea .ec-orderPayment .shoppingPage-radioArea input[type=radio]:checked + label span::after {
  opacity: 1; }

.shoppingPage-orderArea .ec-orderConfirm .ec-input textarea {
  height: 160px;
  border-radius: 0; }

/* ec-orderRole__summary */
.shoppingPage-orderArea .ec-orderRole__summary {
  width: 331px;
  padding: 0;
  margin: 0; }

.shoppingPage-orderArea .ec-orderRole__summary .ec-totalBox {
  background-color: #F9F9F9; }

.shoppingPage-orderArea .ec-orderRole__summary .ec-totalBox .ec-totalBox__spec {
  font-size: 16px; }

.shoppingPage-orderArea .ec-orderRole__summary .ec-totalBox .ec-totalBox__total {
  padding-bottom: 40px;
  font-weight: 500;
  font-size: 14px; }

.shoppingPage-orderArea .ec-orderRole__summary .ec-totalBox .ec-totalBox__total .ec-totalBox__price {
  color: #BF0404;
  font-weight: 500;
  font-size: 24px; }

.shoppingPage-orderArea .ec-orderRole__summary .ec-totalBox .ec-totalBox__total .ec-totalBox__taxLabel {
  color: #BF0404;
  font-size: 14px; }

/* shoppingPage-productList */
.shoppingPage-productList {
  width: 100%;
  margin-bottom: 40px; }
  .shoppingPage-productList .ec-orderDelivery__item {
    max-height: 400px;
    overflow-y: scroll; }

.cart_page .ec-cartRole__cart {
  max-height: 400px;
  overflow-y: scroll; }

/* shoppingPage-completeArea */
.shoppingPage-completeArea-progress {
  margin-bottom: 10px; }

.shoppingPage-completeArea {
  width: 100%; }

.shoppingPage-completeArea .shoppingPage-completeArea-title {
  width: 100%;
  padding: 0;
  margin: 0 0 33px;
  color: #343434;
  font-weight: bold;
  text-align: center;
  font-size: 32px; }

.shoppingPage-completeArea .ec-reportDescription {
  width: 100%;
  max-width: 570px;
  padding: 0;
  margin: 0 auto 30px;
  text-align: center;
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5; }

.shoppingPage-completeArea-numArea {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 280px;
  margin: 0 auto 30px; }

.shoppingPage-completeArea-numArea-term,
.shoppingPage-completeArea-numArea-num {
  width: 52%;
  padding: 0;
  margin: 0;
  text-align: left;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.6; }

.shoppingPage-completeArea-numArea-term {
  width: 48%;
  padding-right: 5px; }

.shoppingPage-completeArea-numArea-num .is-pre {
  padding: 0 26px 0 0;
  margin: 0; }

.shoppingPage-completeArea-text {
  width: 100%;
  margin-bottom: 54px;
  text-align: center; }

.shoppingPage-completeArea-text a {
  display: inline;
  text-decoration: underline; }

.shoppingPage-completeArea-btnArea .ec-blockBtn--cancel
.shoppingPage-completeArea-btnArea .ec-blockBtn--cancel:hover {
  background-color: #767DBB;
  border-color: #767DBB; }

/* entry page */
.ec-registerRole .ec-off1Grid .ec-off1Grid__cell {
  width: 100%;
  margin: 0; }

.ec-registerRole .ec-registerRole__actions .ec-checkbox input {
  display: inline-block; }

.ec-registerRole .ec-registerRole__actions label a {
  display: inline;
  color: #3C82F0;
  text-decoration: underline; }

/* mypage */
.ec-mypageRole {
  padding: 0; }

.ec-login {
  background: transparent; }

.bulkorderPage .ec-pageHeader h1 {
  margin-bottom: 40px; }

/* bulkorderPage-formArea */
.bulkorderPage-formArea {
  width: 100%;
  margin-bottom: 40px; }

.bulkorderPage-formArea-downloadArea {
  display: flex;
  width: 100%;
  margin-bottom: 20px; }

.bulkorderPage-formArea-downloadArea-name {
  display: flex;
  align-items: center;
  width: 135px;
  height: 50px;
  padding: 0;
  margin: 0;
  font-weight: normal;
  font-size: 14px; }

.bulkorderPage-formArea-downloadArea-main {
  display: flex;
  align-items: center;
  width: calc(100% - 135px);
  height: 50px; }

.bulkorderPage-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  background: url(/html/template/default/assets/img/common/icon_select.svg) no-repeat right 15px center #fff;
  background-size: 10px auto;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 350px;
  height: 100%;
  padding: 0 35px 0 20px;
  margin: 0 10px 0 0;
  font-size: 14px;
  line-height: normal;
  cursor: pointer; }

.bulkorderPage-download {
  outline: none;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  padding: 0;
  margin: 0;
  background-color: #F9F9F9;
  border: 1px solid #CCCCCC;
  border-radius: 30px;
  color: #343434;
  font-weight: 500;
  font-size: 14px; }

.bulkorderPage-download:hover {
  opacity: .8; }

.bulkorderPage-formArea-uploadArea {
  display: flex;
  width: 100%; }

.uploadArea .uploadArea-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 155px;
  height: 40px;
  background-color: #F9F9F9;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer; }

.uploadArea .uploadArea-btn:hover {
  opacity: .8; }

.uploadArea .uploadArea-files {
  display: none; }

.uploadArea .uploadArea-filename {
  display: flex;
  align-items: center;
  width: auto;
  max-width: 150px;
  height: 40px;
  padding: 0 15px 0 10px;
  border: none;
  color: #000000;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  cursor: pointer; }

.uploadArea .uploadArea-filename::placeholder {
  width: 150px;
  color: #000000;
  opacity: 1; }

.bulkorderPage-upload {
  outline: none;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  padding: 0;
  margin: 0;
  background-color: #F19800;
  border: none;
  border-radius: 20px;
  color: #fff;
  font-weight: 500;
  font-size: 14px; }

.bulkorderPage-upload:hover {
  opacity: .8; }

/* bulkorderPage-errorArea */
.bulkorderPage-errorArea {
  width: 100%; }

.bulkorderPage-errorArea-tableArea {
  width: 100%;
  margin-bottom: 20px; }

.bulkorderPage-errorArea-tableArea .cartPage-table .cartPage-table-type {
  width: 130px; }

.bulkorderPage-errorArea-tableArea .cartPage-table .cartPage-table-error {
  width: 207px; }

.bulkorderPage-errorArea-btnArea {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  padding-right: 27px; }

.bulkorderPage-errorArea-btnArea .ec-blockBtn--action,
.bulkorderPage-errorArea-btnArea .ec-blockBtn--cancel {
  width: 350px;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 14px; }

.skuPage .bulkorderPage-errorArea-tableArea .cartPage-table .cartPage-table-name {
  width: 320px; }

.skuPage .bulkorderPage-errorArea-tableArea .cartPage-table .cartPage-table-type {
  width: 162px; }

.bulkorderPage-errorArea-tableArea .cartPage-table .cartPage-table-mysku {
  width: 272px; }

.bulkorderPage-errorArea-tableArea .cartPage-table.ec-cartRow .cartPage-table-mysku {
  padding: 25px 26px; }

.bulkorderPage-errorArea-tableArea .cartPage-table .cartPage-table-mysku .cartPage-table-count-inner {
  display: block;
  width: 100%;
  padding: 4px 12px;
  border: 1px solid #CCCCCC;
  text-align: left;
  font-size: 14px;
  line-height: normal; }

.skuPage-errorArea-pagerArea {
  width: 100%;
  margin-bottom: 10px; }

/* skuPage-searchArea */
.skuPage-searchArea {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-bottom: 20px; }

.skuPage-searchArea .bulkorderPage-select {
  margin-right: 20px; }

.skuPage-search-input {
  display: flex;
  align-items: center;
  width: 250px;
  height: 36px;
  padding: 0 20px;
  margin: 0 10px 0 0;
  background-color: #fff;
  border: 2px solid #CCCCCC;
  color: #525263;
  font-size: 14px; }

.skuPage-search-input::placeholder {
  color: #A0A0A0; }

.skuPage-search-btn {
  outline: none;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 136px;
  height: 30px;
  padding: 0;
  margin: 0;
  background-color: #F9F9F9;
  border: 1px solid #CCCCCC;
  border-radius: 20px;
  color: #343434;
  font-weight: 500;
  font-size: 14px; }

.skuPage-search-btn:hover {
  opacity: .8; }

.orderhistoryPage .ec-mypageRole .ec-pageHeader h1 {
  margin-bottom: 38px; }

/* orderhistoryPage-formArea */
.orderhistoryPage-formArea {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-bottom: 34px; }

.orderhistoryPage-formArea-item {
  display: flex;
  align-items: center;
  width: auto;
  height: 50px; }

.orderhistoryPage-formArea-term {
  padding: 0 8px 0 0;
  color: #000000;
  font-weight: normal;
  font-size: 14px; }

.orderhistoryPage-formArea-main {
  display: flex;
  align-items: center;
  width: auto;
  height: 100%; }

.orderhistoryPage-orderPeriod-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 100%;
  padding: 0 14px;
  background-color: #fff;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  color: #343434;
  font-weight: 500;
  font-size: 13px;
  line-height: 0; }

.orderhistoryPage-formArea-main-dateInput {
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  overflow: hidden; }

.orderhistoryPage-formArea-main-dateInput input[type="date"].orderhistoryPage-orderPeriod-input {
  width: 200px;
  padding: 0 10px;
  border: none;
  position: relative; }

.orderhistoryPage-formArea-main-dateInput input[type="date"].orderhistoryPage-orderPeriod-input::-webkit-calendar-picker-indicator {
  width: 110px;
  height: 100%;
  color: transparent;
  cursor: pointer;
  background: transparent;
  position: absolute;
  top: 0;
  left: -24px; }

.orderhistoryPage-orderPeriod .is-dash {
  padding: 0 5px;
  font-size: 14px; }

.orderhistoryPage-orderUser,
.orderhistoryPage-orderState {
  margin-left: 18px; }

.orderhistoryPage-orderUser-select,
.orderhistoryPage-orderState-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  width: 300px;
  height: 100%;
  padding: 0 30px 0 15px;
  background: url(/html/template/default/assets/img/common/icon_select.svg) no-repeat right 15px center #fff;
  background-size: 10px auto;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  color: #000000;
  font-size: 14px;
  line-height: normal;
  cursor: pointer; }

.orderhistoryPage-orderState-select {
  width: 100px;
  font-size: 13px; }

.orderhistoryPage-search {
  width: 180px; }

/* orderhistoryPage-table */
.orderhistoryPage-tableArea {
  padding-right: 65px; }

.orderhistoryPage-table.ec-historyRole {
  position: relative; }

.orderhistoryPage-table.ec-cartRow li {
  display: flex;
  align-items: center;
  padding: 30px 8px; }

.ec-cartRow .orderhistoryPage-table-re {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  width: 100px;
  height: 100%;
  padding: 0;
  border: none;
  position: absolute;
  top: 0;
  right: -100px; }

.orderhistoryPage-table-reBtn {
  display: block;
  width: 100px;
  padding: 4px;
  background-color: #BF0404;
  border: none;
  color: #fff;
  border-radius: 20px;
  border: none;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  line-height: normal; }

.orderhistoryPage-table-reBtn + .orderhistoryPage-table-reBtn {
  margin-top: 5px; }

.orderhistoryPage-table-reBtn:hover {
  color: #fff;
  opacity: .8; }

.ec-cartRow .orderhistoryPage-table-num,
.ec-cartRow .orderhistoryPage-table-state,
.ec-cartRow .orderhistoryPage-table-deliv {
  justify-content: center; }

.orderhistoryPage-table-num {
  width: 100px; }

.orderhistoryPage-table-num a {
  color: #343434;
  text-align: center; }

.orderhistoryPage-table-date {
  width: 103px; }

.orderhistoryPage-table-state {
  width: 63px; }

.orderhistoryPage-table-user {
  width: 176px; }

.orderhistoryPage-table-price {
  width: 102px; }

.ec-cartRow .orderhistoryPage-table-price {
  text-align: right; }

.orderhistoryPage-table-address {
  width: 30%; }

.orderhistoryPage-table-deliv {
  width: 104px; }

/* orderhistoryPage-csvArea */
.orderhistoryPage-csvArea {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-top: 20px; }

.orderhistoryPage-csvArea-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 60px;
  background-color: #F9F9F9;
  border: 1px solid #CCCCCC;
  border-radius: 30px;
  color: #343434;
  font-weight: 500;
  font-size: 14px;
  line-height: normal; }

.orderhistoryPage-csvArea-btn:hover {
  opacity: .8; }

.orderhistoryDetailPage .ec-pageHeader h1 {
  margin-bottom: 40px; }

.orderhistoryDetailPage .ec-orderRole {
  margin-top: 0; }

.ec-orderRole .ec-orderRole__detail.orderhistoryDetailPage-main {
  width: 100%;
  padding: 0; }

.orderhistoryDetailPage-main .ec-orderOrder {
  margin-bottom: 56px; }

.orderhistoryDetailPage-main .ec-orderOrder .ec-rectHeading h2,
.orderhistoryDetailPage-main .ec-orderDelivery .ec-rectHeading h2 {
  margin-bottom: 35px; }

/* orderhistoryDetailPage-infoArea */
.orderhistoryDetailPage-infoArea {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0 0 15px;
  font-size: 16px;
  line-height: 1.6; }

.orderhistoryDetailPage-infoArea dt {
  width: 126px;
  padding: 0 3px 0 20px;
  margin: 0;
  font-weight: normal; }

.orderhistoryDetailPage-infoArea dd {
  width: calc(100% - 126px);
  font-weight: normal; }

.orderhistoryDetailPage-infoArea dd .is-pre {
  margin-right: 23px; }

/* orderhistoryDetailPage-productList */
.orderhistoryDetailPage-productList {
  width: 100%;
  margin-bottom: 60px; }

.orderhistoryDetailPage-delivList {
  width: 100%; }

.orderhistoryDetailPage-delivList .cartPage-table-day {
  width: 104px; }

.orderhistoryDetailPage .ec-cartRow .cartPage-table-day {
  text-align: center; }

.orderhistoryDetailPage-delivList .cartPage-table-no {
  width: 113px; }

.orderhistoryDetailPage-delivList .cartPage-table-mysku {
  width: 80px; }

.orderhistoryDetailPage-delivList .cartPage-table-name {
  width: 61%; }

.orderhistoryDetailPage-delivList .cartPage-table-type {
  width: 136px; }

.orderhistoryDetailPage-delivList .cartPage-table-count {
  width: 76px; }

.orderhistoryDetailPage-delivList .cartPage-table-unit {
  width: 74px; }

.underPage p + p,
.underPage P + ul,
.underPage ul + p {
  margin-top: 15px; }

.underPage .ec-off1Grid + .ec-off1Grid {
  margin-top: 30px; }
