.orderhistoryDetailPage .ec-pageHeader h1 {
  margin-bottom: 40px;
}
.orderhistoryDetailPage .ec-orderRole {
  margin-top: 0;
}
.ec-orderRole .ec-orderRole__detail.orderhistoryDetailPage-main {
  width: 100%;
  padding: 0;
}
.orderhistoryDetailPage-main .ec-orderOrder {
  margin-bottom: 56px;
}
.orderhistoryDetailPage-main .ec-orderOrder .ec-rectHeading h2,
.orderhistoryDetailPage-main .ec-orderDelivery .ec-rectHeading h2 {
  margin-bottom: 35px;
}

/* orderhistoryDetailPage-infoArea */
.orderhistoryDetailPage-infoArea {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0 0 15px;
  font-size: 16px;
  line-height: 1.6;
}
.orderhistoryDetailPage-infoArea dt {
  width: 126px;
  padding: 0 3px 0 20px;
  margin: 0;
  font-weight: normal;
}
.orderhistoryDetailPage-infoArea dd {
  width: calc(100% - 126px);
  font-weight: normal;
}
.orderhistoryDetailPage-infoArea dd .is-pre {
  margin-right: 23px;
}

/* orderhistoryDetailPage-productList */
.orderhistoryDetailPage-productList {
  width: 100%;
  margin-bottom: 60px;
}
.orderhistoryDetailPage-delivList {
  width: 100%;
}
.orderhistoryDetailPage-delivList .cartPage-table-day {
  width: 104px;
}
.orderhistoryDetailPage .ec-cartRow .cartPage-table-day {
  text-align: center;
}
.orderhistoryDetailPage-delivList .cartPage-table-no {
  width: 113px;
}
.orderhistoryDetailPage-delivList .cartPage-table-mysku {
  width: 80px;
}
.orderhistoryDetailPage-delivList .cartPage-table-name {
  // width: 50%;
  width: 61%;
}
.orderhistoryDetailPage-delivList .cartPage-table-type {
  width: 136px;
}
.orderhistoryDetailPage-delivList .cartPage-table-count {
  width: 76px;
}
.orderhistoryDetailPage-delivList .cartPage-table-unit {
  width: 74px;
}
