.bulkorderPage .ec-pageHeader h1 {
  margin-bottom: 40px;
}

/* bulkorderPage-formArea */
.bulkorderPage-formArea {
  width: 100%;
  margin-bottom: 40px;
}
.bulkorderPage-formArea-downloadArea {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
}
.bulkorderPage-formArea-downloadArea-name {
  display: flex;
  align-items: center;
  width: 135px;
  height: 50px;
  padding: 0;
  margin: 0;
  font-weight: normal;
  font-size: 14px;
}
.bulkorderPage-formArea-downloadArea-main {
  display: flex;
  align-items: center;
  width: calc(100% - 135px);
  height: 50px;
}
.bulkorderPage-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  background: url(/html/template/default/assets/img/common/icon_select.svg) no-repeat right 15px center #fff;
  background-size: 10px auto;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  display: flex;
  align-items: center;
  width: 350px;
  height: 100%;
  padding: 0 35px 0 20px;
  margin: 0 10px 0 0;
  font-size: 14px;
  line-height: normal;
  cursor: pointer;
}
.bulkorderPage-download {
  outline: none;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  padding: 0;
  margin: 0;
  background-color: #F9F9F9;
  border: 1px solid #CCCCCC;
  border-radius: 30px;
  color: #343434;
  font-weight: 500;
  font-size: 14px;
}
.bulkorderPage-download:hover {
  opacity: .8;
}
.bulkorderPage-formArea-uploadArea {
  display: flex;
  width: 100%;
}
.uploadArea .uploadArea-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 155px;
  height: 40px;
  background-color: #F9F9F9;
  border: 1px solid #CCCCCC;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
}
.uploadArea .uploadArea-btn:hover {
  opacity: .8;
}
.uploadArea .uploadArea-files {
  display: none;
}
.uploadArea .uploadArea-filename {
  display: flex;
  align-items: center;
  width: auto;
  max-width: 150px;
  height: 40px;
  padding: 0 15px 0 10px;
  border: none;
  color: #000000;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.5;
  cursor: pointer;
}
.uploadArea .uploadArea-filename::placeholder {
  width: 150px;
  color: #000000;
  opacity: 1;
}
.bulkorderPage-upload {
  outline: none;
  appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  padding: 0;
  margin: 0;
  background-color: #F19800;
  border: none;
  border-radius: 20px;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
}
.bulkorderPage-upload:hover {
  opacity: .8;
}


/* bulkorderPage-errorArea */
.bulkorderPage-errorArea {
  width: 100%;
}
.bulkorderPage-errorArea-tableArea {
  width: 100%;
  margin-bottom: 20px;
}
.bulkorderPage-errorArea-tableArea .cartPage-table .cartPage-table-type {
  width: 130px;
}
.bulkorderPage-errorArea-tableArea .cartPage-table .cartPage-table-error {
  width: 207px;
}
.bulkorderPage-errorArea-btnArea {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  padding-right: 27px;
}
.bulkorderPage-errorArea-btnArea .ec-blockBtn--action,
.bulkorderPage-errorArea-btnArea .ec-blockBtn--cancel {
  width: 350px;
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 14px;
}